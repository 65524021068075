<i18n src="@/i18n/locales/event.en.json"></i18n>
<i18n src="@/i18n/locales/event.fr.json"></i18n>

<template>
  <div v-if="containers && containers.length > 0" class="mt-4 px-4 mb-4">
    <div class="columns mt-1">
      <div class="column is-2 pt-0">
        <div
          class="column-containers is-fullheight is-scrollable-y box is-radiusless p-3"
        >
          <ContainersBoardListItemLabel
            v-for="(elem, key) in containers"
            :title="elem.container.reference || elem.container.bookingNumber"
            :indicator="elem.container.indicator"
            :container-index="key"
            :key="key"
            :is-active="selectedIndex === key"
            :is-active-popup="popupSelectedIndex === key"
            @click-label="onClickItemLabelHandler"
            @click-info="onClickItemLabelInfoHandler"
          />
        </div>
      </div>
      <div v-if="isShowContainerGeneralInfo" class="column is-3 pt-0 px-2">
        <ContainersBoardItemGeneralInfo
          :container="containers[selectedIndex].container"
          :isImport="isImport"
        />
      </div>
      <div
        class="column pt-0"
        :class="isShowContainerGeneralInfo ? 'is-7' : 'is-10'"
      >
        <ContainersBoardItemDetails
          v-if="selectedIndex > -1"
          :histories="containers[selectedIndex].histories"
          :event-timelines="eventTimelines[selectedIndex]"
          :history-key="selectedIndex"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContainersBoardListItemLabel from "./ContainersBoardListItemLabel.vue";
import ContainersBoardItemGeneralInfo from "./ContainersBoardItemGeneralInfo.vue";
import ContainersBoardItemDetails from "./ContainersBoardItemDetails.vue";

import EventStatus from "@/helpers/eventStatus.js";
import { repeatableEventsArray } from "@/helpers/constants.js";

import _camelCase from "lodash/camelCase";

export default {
  name: "ContainerBoard",
  components: {
    ContainersBoardListItemLabel,
    ContainersBoardItemGeneralInfo,
    ContainersBoardItemDetails
  },

  props: {
    containers: {
      type: Array,
      required: true
    },
    isImport: {
      type: Boolean,
      required: true,
      default: true
    },
    historyKey: Number
  },

  data() {
    return {
      popupSelectedIndex: -1,
      selectedIndex: 0,
      /**
       * Event timeline items of containers.
       * Each element is an array of event timeline items of
       * the container having the same index in the containers array.
       */
      eventTimelines: []
    };
  },

  computed: {
    isShowContainerGeneralInfo: function() {
      return this.popupSelectedIndex !== -1;
    },
    containersLength: function() {
      return this.containers?.length ?? 0;
    }
  },

  methods: {
    onClickItemLabelHandler: function(newSelectedIndex) {
      this.selectedIndex = newSelectedIndex;

      if (this.popupSelectedIndex !== -1)
        this.popupSelectedIndex = newSelectedIndex;
    },

    onClickItemLabelInfoHandler: function(newSelectedIndex) {
      this.popupSelectedIndex =
        newSelectedIndex === this.popupSelectedIndex ? -1 : newSelectedIndex;

      if (
        this.popupSelectedIndex !== -1 &&
        this.selectedIndex !== newSelectedIndex
      )
        this.selectedIndex = newSelectedIndex;
    },

    /**
     * Generate timeline items from container's events data.
     */
    getTimelineItems: function() {
      const timelines = [];

      for (const containerBoardData of this.containers) {
        let groupedEvents = this.groupEventsByType(
          containerBoardData.container.events
        ).sort((a, b) => {
          let d1 = new Date(a.history[0]);
          let d2 = new Date(b.history[0]);
          return d1.getTime() - d2.getTime();
        });

        let res = [];

        for (let i = 0; i < groupedEvents.length; i++) {
          res.push({
            history: groupedEvents[i].history,
            startDate: groupedEvents[i].startDate,
            endDate: groupedEvents[i].endDate,
            title1:
              "type." +
              _camelCase(groupedEvents[i].eventStatus.state) +
              "." +
              _camelCase(groupedEvents[i].type),
            state: groupedEvents[i].eventStatus.state,
            location: groupedEvents[i].location,
            creationDate: groupedEvents[i].creationDate,
            incidents: groupedEvents[i].incidents
          });
        }

        timelines.push(res);
      }

      return timelines;
    },

    /**
     * Group events by type.
     */
    groupEventsByType: function(events) {
      const pushIfNew = (array, newValue) => {
        if (newValue && !array.includes(newValue)) array.push(newValue);
      };

      let res = events.reduce(function(result, event) {
        let existedEvent = result.find(
          e =>
            e.type == event.type && !repeatableEventsArray.includes(event.type)
        );

        if (existedEvent) {
          existedEvent.history ??= []; // initialize if null

          // push event date to history if any
          pushIfNew(existedEvent.history, event.startDate);
          pushIfNew(existedEvent.history, event.endDate);

          // update start date and end date if the event is newer than the existed
          if (existedEvent.eventStatus.isOlderThan(event.state)) {
            existedEvent.eventStatus = new EventStatus(event.state);
            existedEvent.startDate = event.startDate;
            existedEvent.endDate = event.endDate;
            existedEvent.creationDate = event.creationDate;
            existedEvent.incidents = existedEvent.incidents?.concat(
              event.incidents
            );
          }
        } else {
          // eslint-disable-next-line no-undef
          let clonedEvent = structuredClone(event);
          result.push(clonedEvent); // push event to the grouped list if not found
          clonedEvent.history ??= []; // initialize if null

          clonedEvent.eventStatus = new EventStatus(event.state);

          // push event date to history if any
          pushIfNew(clonedEvent.history, clonedEvent.startDate);
          pushIfNew(clonedEvent.history, clonedEvent.endDate);
        }

        return result;
      }, []);

      // sort history in ascending order
      res.forEach(e => {
        e.history.sort((a, b) => {
          let d1 = new Date(a);
          let d2 = new Date(b);
          return d1.getTime() - d2.getTime();
        });
      });

      return res;
    }
  },

  watch: {
    // Update the event timelines when the length of the containers array changes
    containersLength: function(newValue) {
      let timelines = [];

      if (newValue == 0) timelines = [];
      else timelines = this.getTimelineItems();

      this.eventTimelines = timelines;
    }
  },

  created() {
    if (this.containersLength) {
      this.eventTimelines = this.getTimelineItems();
    }
  }
};
</script>

<style>
.column-containers {
  min-height: 60vh;
}

.is-scrollable-y {
  overflow-y: scroll;
}

.is-fullheight {
  height: 100% !important;
}
</style>
