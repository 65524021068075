/**
 * Event status class helping to sort them by weight.
 */
export default class EventStatus {
  /**
   * List of possible states an event can have.
   */
  static states = ["inprogress", "ko", "ok"];
  /**
   * Current state.
   */
  state;
  /**
   * Weight of the current state.
   */
  stateWeight = -1;

  constructor(state) {
    this.state = state;
    if (state) this.stateWeight = EventStatus.getWeight(state);
  }

  /**
   * Get weight of the state.
   * @param {string} state - State value
   * @returns {int} The weight of the state.
   */
  static getWeight(state) {
    return this.states.findIndex(sts => sts === state?.toLowerCase());
  }

  /**
   * Compare 2 states.
   * @param {string} state1 - Comparing state
   */
  compare(state1) {
    const state1Weight = EventStatus.getWeight(state1);

    if (this.stateWeight < state1Weight) return 1;
    if (this.stateWeight === state1Weight) return 0;
    return -1;
  }

  /**
   * Consider if the current state is older than the comparing state.
   * @param {string} state1 - Comparing state
   */
  isOlderThan(state1) {
    return this.compare(state1) > 0;
  }
}
