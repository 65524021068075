<i18n src="@/i18n/locales/container-info.en.json"></i18n>
<i18n src="@/i18n/locales/container-info.fr.json"></i18n>

<template>
  <ValidationObserver
    ref="containersObserver"
    :key="containerIndex"
    :class="{
      'dark-mode': $root.isDarkMode
    }"
  >
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
    <div class="columns is-desktop">
      <div class="column">
        <div class="columns">
          <!-- Container reference -->
          <div class="column is-one-third-tablet">
            <InputWithValidation
              id="container-reference"
              rules="length:11|regex:^[a-zA-Z]{4}[0-9]{7}$"
              :label="$t('containerReference')"
              validation-name="containerReference"
              v-model="containerReference"
              :disabled="isEditingOrder"
              :is-required="isCustomsFlagImport"
              @input="
                onContainerInformationChange('reference', containerReference)
              "
            />
          </div>

          <!-- B/L number | Booking number -->
          <div class="column is-one-third-tablet">
            <InputWithValidation
              id="bill-of-lading"
              v-if="isCustomsFlagImport"
              :label="$t('billOfLading')"
              validation-name="billOfLading"
              :disabled="!isEditable"
              v-model="billOfLading"
              @input="
                onContainerInformationChange('billOfLading', billOfLading)
              "
            />

            <InputWithValidation
              v-else
              is-required
              :label="$t('bookingNumber')"
              validation-name="bookingNumber"
              :disabled="isEditingOrder"
              v-model="bookingNumber"
              @input="
                onContainerInformationChange('bookingNumber', bookingNumber)
              "
            />
          </div>

          <!-- Container size type -->
          <div class="column is-one-third-tablet">
            <Autocomplete
              id="size-type"
              v-model="containerType"
              :label="$t('sizeTypeCode')"
              :search-property="'code'"
              validation-name="sizeTypeCode"
              :validation-rules="{
                required: !isCustomsFlagImport,
                length: { length: 4 }
              }"
              :is-required="!isCustomsFlagImport"
              :max-length="4"
              :is-fixed-length="true"
              :is-description-displayed="true"
              :label-position="'on-border'"
              :description-property="'description'"
              :fetch-all-async="getAllContainerTypesAsync"
              :fetch-async="getContainerTypeAsync"
              :is-autocomplete-field-expanded="true"
              :disabled="!isEditable"
              @input="onContainerInformationChange('type', containerType)"
            />
          </div>
        </div>
      </div>

      <div class="column">
        <div class="columns">
          <!-- Gross weight -->
          <div class="column">
            <ValidationProvider
              rules="double|min_value:0"
              v-slot="{ errors }"
              name="grossWeight"
            >
              <b-field :label="$t('grossWeight')" label-position="on-border">
                <b-input
                  id="gross-weight"
                  :value="convertStringWithLocaleDecimalSeparator(grossWeight)"
                  expanded
                  @input="val => (grossWeight = trimLeadingZeros(val))"
                  @blur="
                    onContainerInformationChange('grossWeight', grossWeight)
                  "
                  :disabled="!isEditable"
                />
                <p class="control">
                  <span class="button is-static">
                    {{ $t("weightUnit") }}
                  </span>
                </p>
                <template #message>
                  <p class="has-text-danger">
                    {{ errors[0] }}
                  </p>
                </template>
              </b-field>
            </ValidationProvider>
          </div>

          <!-- Tare weight -->
          <div class="column">
            <ValidationProvider
              rules="double|min_value:0"
              v-slot="{ errors }"
              name="tare"
            >
              <b-field :label="$t('tare')" label-position="on-border">
                <b-input
                  id="tare"
                  :value="convertStringWithLocaleDecimalSeparator(tare)"
                  expanded
                  @input="val => (tare = trimLeadingZeros(val))"
                  @blur="onContainerInformationChange('tare', tare)"
                  :disabled="!isEditable"
                />
                <p class="control">
                  <span class="button is-static">
                    {{ $t("weightUnit") }}
                  </span>
                </p>
                <template #message>
                  <p class="has-text-danger">
                    {{ errors[0] }}
                  </p>
                </template>
              </b-field>
            </ValidationProvider>
          </div>

          <!-- Seal numbers -->
          <div class="column">
            <b-tooltip
              :label="$t('sealNumbers')"
              class="is-fullwidth"
              :triggers="['click']"
              :auto-close="['outside']"
              :active="canSealNumbersTooltipActive"
            >
              <b-field label-position="on-border"
                ><template #label>
                  <label
                    class="mb-6"
                    @click="canSealNumbersTooltipActive = true"
                  >
                    {{ $t("sealNumbers") }}
                  </label>
                </template>
                <b-taginput
                  id="seal-numbers"
                  v-model="sealNumbers"
                  ellipsis
                  icon-pack="fas"
                  icon="tag"
                  attached
                  maxlength="64"
                  @focus="canSealNumbersTooltipActive = false"
                  @input="
                    onContainerInformationChange('sealNumbers', sealNumbers)
                  "
                  :disabled="!isEditable"
                >
                </b-taginput>
              </b-field>
            </b-tooltip>
          </div>

          <!-- Container indicators -->
          <div class="column">
            <b-field>
              <b-checkbox-button
                id="is-reefer"
                v-model="isReefer"
                type="is-primary"
                :native-value="true"
                expanded
                @input="onContainerInformationChange('isReefer', isReefer)"
                :disabled="!isEditable"
              >
                <b-tooltip :label="$t('reefer')">
                  <b-icon pack="fas" icon="asterisk"></b-icon>
                </b-tooltip>
              </b-checkbox-button>

              <b-checkbox-button
                id="is-oog"
                v-model="isOog"
                type="is-primary"
                :native-value="true"
                expanded
                @input="onContainerInformationChange('isOog', isOog)"
                :disabled="!isEditable"
              >
                <b-tooltip :label="$t('oog')">
                  <b-icon pack="fab" icon="codepen"></b-icon>
                </b-tooltip>
              </b-checkbox-button>

              <b-checkbox-button
                id="is-hazardous"
                v-model="isHazardous"
                type="is-danger"
                :native-value="true"
                expanded
                @input="
                  onContainerInformationChange('isHazardous', isHazardous)
                "
                :disabled="!isEditable"
              >
                <b-tooltip :label="$t('hazardous')" type="is-danger">
                  <b-icon pack="fas" icon="skull-crossbones"></b-icon>
                </b-tooltip>
              </b-checkbox-button>
            </b-field>
          </div>

          <!-- Reefer temperature -->
          <div class="column" v-if="isReefer">
            <ValidationProvider
              rules="double"
              v-slot="{ errors }"
              name="reeferTemperature"
            >
              <b-field
                :label="$t('reeferTemperature')"
                label-position="on-border"
              >
                <b-input
                  expanded
                  :value="
                    convertStringWithLocaleDecimalSeparator(reeferTemperature)
                  "
                  @input="val => (reeferTemperature = trimLeadingZeros(val))"
                  @blur="
                    onContainerInformationChange('setPoint', reeferTemperature)
                  "
                  :disabled="!isEditable"
                />
                <p class="control">
                  <span class="button is-static">
                    {{ $t("temperatureUnit") }}
                  </span>
                </p>
                <template #message>
                  <p class="has-text-danger">
                    {{ errors[0] }}
                  </p>
                </template>
              </b-field>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <!-- Exceeding length, width, height -->
      <div class="column mb-3" v-if="isOog">
        <ValidationProvider
          rules="double|min_value:0"
          v-slot="{ errors }"
          name="exceedingLength"
        >
          <b-field :label="$t('exceedingLength')" label-position="on-border">
            <b-input
              expanded
              :value="convertStringWithLocaleDecimalSeparator(exceedingLength)"
              @input="val => (exceedingLength = trimLeadingZeros(val))"
              @blur="onContainerInformationChange('length', exceedingLength)"
              :disabled="!isEditable"
            />
            <p class="control">
              <span class="button is-static">
                {{ $t("sizeUnit") }}
              </span>
            </p>
            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>
          </b-field>
        </ValidationProvider>
      </div>

      <div class="column" v-if="isOog">
        <ValidationProvider
          rules="double|min_value:0"
          v-slot="{ errors }"
          name="exceedingWidth"
        >
          <b-field :label="$t('exceedingWidth')" label-position="on-border">
            <b-input
              expanded
              :value="convertStringWithLocaleDecimalSeparator(exceedingWidth)"
              @input="val => (exceedingWidth = trimLeadingZeros(val))"
              @blur="onContainerInformationChange('width', exceedingWidth)"
              :disabled="!isEditable"
            />
            <p class="control">
              <span class="button is-static">
                {{ $t("sizeUnit") }}
              </span>
            </p>
            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>
          </b-field>
        </ValidationProvider>
      </div>

      <div class="column" v-if="isOog">
        <ValidationProvider
          rules="double|min_value:0"
          v-slot="{ errors }"
          name="exceedingHeight"
        >
          <b-field :label="$t('exceedingHeight')" label-position="on-border">
            <b-input
              expanded
              :value="convertStringWithLocaleDecimalSeparator(exceedingHeight)"
              @input="val => (exceedingHeight = trimLeadingZeros(val))"
              @blur="onContainerInformationChange('height', exceedingHeight)"
              :disabled="!isEditable"
            />
            <p class="control">
              <span class="button is-static">
                {{ $t("sizeUnit") }}
              </span>
            </p>
            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>
          </b-field>
        </ValidationProvider>
      </div>
    </div>

    <!-- Customs information -->
    <div class="columns">
      <div class="column">
        <ValidationProvider
          rules="max:64"
          v-slot="{ errors }"
          name="customsInformation"
        >
          <b-field
            :label="$t('customsInformation')"
            label-position="on-border"
            :custom-class="showCustomsInformation ? '' : 'transparent-label'"
          >
            <b-input
              v-if="showCustomsInformation"
              expanded
              v-model="customsInformation"
              @blur="
                onContainerInformationChange(
                  'customsInformation',
                  customsInformation
                )
              "
              :disabled="!isEditable"
            />
            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>

            <div
              v-if="!showCustomsInformation"
              class="mr-4 is-flex is-justify-content-flex-end expand-arrow"
            >
              <b-icon
                pack="fas"
                icon="chevron-down"
                class="is-size-6 is-clickable"
                @click.native="showCustomsInformation = true"
              />
            </div>
          </b-field>
        </ValidationProvider>
      </div>

      <div
        v-if="showCustomsInformation"
        class="mr-4 is-flex is-justify-content-flex-end column is-1"
      >
        <b-icon
          pack="fas"
          icon="chevron-up"
          class="is-size-6 is-clickable"
          @click.native="showCustomsInformation = false"
        />
      </div>
    </div>

    <!-- Comments -->
    <div class="columns mt-3">
      <div class="column">
        <ValidationProvider rules="max:1024" v-slot="{ errors }" name="comment">
          <b-field
            :label="$t('comment')"
            label-position="on-border"
            :custom-class="showComment ? '' : 'transparent-label'"
          >
            <b-input
              v-if="showComment"
              expanded
              v-model="comment"
              type="textarea"
              @blur="onContainerInformationChange('comment', comment)"
              :disabled="!isEditable"
            />
            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>

            <div
              v-if="!showComment"
              class="mr-4 is-flex is-justify-content-flex-end expand-arrow"
            >
              <b-icon
                pack="fas"
                icon="chevron-down"
                class="is-size-6 is-clickable"
                @click.native="showComment = true"
              />
            </div>
          </b-field>
        </ValidationProvider>
      </div>

      <div
        v-if="showComment"
        class="mr-4 is-flex is-justify-content-flex-end column is-1"
      >
        <b-icon
          pack="fas"
          icon="chevron-up"
          class="is-size-6 is-clickable"
          @click.native="showComment = false"
        />
      </div>
    </div>

    <!-- Event information -->
    <b-tabs v-model="eventsActiveTab" type="is-boxed" :animated="false">
      <b-tab-item
        v-for="(event, index) in events"
        :key="event.type"
        :value="index.toString()"
        :label="$t('event.' + event.type)"
      >
        <EventEditor
          :is-validate-containers="isValidateContainers"
          :prop-event="event"
          :event-index="index"
          :is-editable="editableEvents[index]"
          @set-event-information="onEventInformationChange"
          @set-event-having-errors="setEventHavingErrors"
        />
      </b-tab-item>
    </b-tabs>

    <!-- Goods information -->
    <b-tabs
      v-model="goodsActiveTab"
      type="is-boxed"
      :animated="false"
      :key="goodsTabsKey"
    >
      <b-tab-item disabled>
        <template #header>
          <span class="has-text-weight-bold tab-header">
            {{ $t("goods") }}
          </span>
        </template>
      </b-tab-item>

      <b-tab-item v-for="(goods, index) in listGoods" :key="index">
        <template #header>
          <span>
            {{ $t("item") + " " + (index + 1) }}
            <span @click="deleteGoods(index)" v-if="isEditable">
              <b-icon pack="fas" icon="times" size="is-small"
            /></span>
          </span>
        </template>
        <GoodsEditor
          :is-validate-containers="isValidateContainers"
          :prop-goods="goods"
          :goods-index="index"
          :not-dangerous-goods="!isHazardous"
          :is-disabled="!isEditable"
          @set-goods-information="onGoodsInformationChange"
          @set-goods-having-errors="setGoodsHavingErrors"
        />
      </b-tab-item>

      <b-tab-item v-if="isEditable">
        <template #header>
          <span class="add-btn" @click="addGoods">
            <b-icon pack="fas" icon="plus" size="is-small" />
          </span>
        </template>
      </b-tab-item>
    </b-tabs>
  </ValidationObserver>
</template>

<script>
import Vue from "vue";

import Autocomplete from "@/components/common/Autocomplete";
import EventEditor from "@/components/customer-order/container-info/EventEditor.vue";
import GoodsEditor from "@/components/customer-order/container-info/GoodsEditor.vue";
import InputWithValidation from "@/components/common/InputWithValidation.vue";
import {
  allowedEventsArray,
  importEventsArray,
  exportEventsArray,
  IMPORT_EVENT_LADING,
  IMPORT_EVENT_DELIVERY,
  IMPORT_EVENT_EMPTY_RELEASE,
  IMPORT_EVENT_EMPTY_RETURN,
  EXPORT_EVENT_EMPTY_RELEASE,
  EXPORT_EVENT_POSITIONING,
  EXPORT_EVENT_LADING,
  EXPORT_EVENT_DELIVERY
} from "@/helpers/constants.js";
import containerTypesApi from "@/repository/referential/containerTypes.api";
import locationsApi from "@/repository/referential/locations.api";
import handlingUnitsApi from "@/repository/customer-order/handlingUnits.api";
import number from "@/mixins/number";
import _ from "lodash";
import myLocationsApi from "../../../repository/customer-order/myLocations.api";

const containerReferenceRegex = /^[A-Za-z]{4}\d{7}$/;

export default {
  name: "ContainerEditor",

  components: {
    Autocomplete,
    EventEditor,
    GoodsEditor,
    InputWithValidation
  },

  mixins: [number],

  props: {
    propContainer: {
      type: Object,
      default: null,
      required: true
    },

    containerIndex: {
      type: Number,
      required: true
    },

    customsFlag: String,

    isValidateContainers: {
      type: Boolean,
      default: false,
      require: true
    },

    // used to pass the first event error tab (if any) when validating order and rerender ContainerInfo (to show right error tab)
    propEventActiveTab: {
      type: Number,
      default: 0
    },

    // used to pass the first goods error tab (if any) when validating order and rerender ContainerInfo (to show right error tab)
    propGoodsActiveTab: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      isLoading: false,
      canSealNumbersTooltipActive: false,
      getAllContainerTypesAsync: containerTypesApi.getAll,
      getContainerTypeAsync: containerTypesApi.get,

      events: [],
      prefilledEventIndexes: [],

      containerReference: this.propContainer.reference,
      billOfLading: this.propContainer.billOfLading,
      bookingNumber: this.propContainer.bookingNumber,
      containerType: this.propContainer.type,

      isReefer: this.propContainer.indicator?.isReefer,
      isOog: this.propContainer.indicator?.isOog,
      isHazardous: this.propContainer.indicator?.isHazardous,

      reeferTemperature: this.propContainer.reefer?.setPoint,

      exceedingLength: this.propContainer.oog?.length,
      exceedingWidth: this.propContainer.oog?.width,
      exceedingHeight: this.propContainer.oog?.height,

      grossWeight: this.propContainer.grossWeight,
      tare: this.propContainer.tare,
      sealNumbers: this.propContainer.sealNumbers,

      customsInformation: this.propContainer.customsInformation,
      comment: this.propContainer.comment,

      listGoods: this.propContainer.goods ?? [],
      goodsTabsKey: 0,
      goodsActiveTab: this.propGoodsActiveTab,
      firstGoodsHavingErrors: null,

      isEditingOrder: this.$route.params?.orderIdentifier !== undefined,
      showCustomsInformation:
        this.propContainer.customsInformation !== undefined,
      showComment: this.propContainer.comment !== undefined,

      eventsActiveTab: this.propEventActiveTab,
      firstEventHavingErrors: null,
      isEditable: true // determine if this container is editable or not
    };
  },

  watch: {
    async containerReference() {
      if (
        !window._config.app.CONFIG_PREFILL_CONTAINER_INFOS ||
        !this.$root.isPrefill
      ) {
        return;
      }

      if (containerReferenceRegex.test(this.containerReference)) {
        this.isLoading = true;
        await handlingUnitsApi
          .getLatestHandlingUnit(this.containerReference, this.customsFlag)
          .then(async response => {
            let data = response.data;
            if (data == null) return;

            this.$root.prefillContainer = {
              ...data
            };

            this.billOfLading = data.billOfLading;
            this.onContainerInformationChange(
              "billOfLading",
              this.billOfLading
            );

            this.containerType = data.type;
            this.onContainerInformationChange("type", this.containerType);

            this.sealNumbers = data.sealNumbers;
            this.onContainerInformationChange("sealNumbers", this.sealNumbers);

            this.grossWeight = data.grossWeight;
            this.onContainerInformationChange("grossWeight", this.grossWeight);

            this.tare = data.tare;
            this.onContainerInformationChange("tare", this.tare);

            this.isReefer = data.indicator?.isReefer;
            this.onContainerInformationChange("isReefer", this.isReefer);

            this.isHazardous = data.indicator?.isHazardous;
            this.onContainerInformationChange("isHazardous", this.isHazardous);

            this.isOog = data.indicator?.isOog;
            this.onContainerInformationChange("isOog", this.isOog);

            this.reeferTemperature = data.reefer?.setPoint;
            this.onContainerInformationChange(
              "setPoint",
              this.reeferTemperature
            );

            // Reset prefilled events with previous data
            for (const index of this.prefilledEventIndexes) {
              if (this.events[index].location.name)
                Vue.set(this.events[index].location, "name", null);
              if (this.events[index].location.code)
                Vue.set(this.events[index].location, "code", null);
              if (this.events[index].location.location)
                Vue.set(this.events[index].location, "location", null);
              if (this.events[index].location.contact)
                Vue.set(this.events[index].location, "contact", null);
            }

            this.prefilledEventIndexes = [];

            if (data.events) {
              for (const prefillEvent of data.events) {
                let index = this.events.findIndex(
                  e => e.type == prefillEvent.type
                );

                if (index > -1) {
                  this.prefilledEventIndexes.push(index);
                  let locationCode = prefillEvent.location.code;

                  await locationsApi
                    .get({
                      code: locationCode
                    })
                    .then(async response => {
                      Vue.set(
                        this.events[index].location,
                        "name",
                        response.data?.name
                      );

                      // Prefill other fields of the events
                      const res = await myLocationsApi.getAllMyLocations(null, {
                        code: locationCode,
                        limit: 1
                      });

                      const myLocationId = res.data.items?.at(0)?.id;

                      if (myLocationId) {
                        const myLocation = await myLocationsApi.getMyLocationById(
                          { id: myLocationId }
                        );

                        Vue.set(this.events[index].location, "location", {
                          address: myLocation?.address,
                          zipCode: myLocation?.postalCode,
                          city: myLocation?.city,
                          country: myLocation?.country
                        });

                        Vue.set(this.events[index].location, "contact", {
                          name: myLocation?.contact?.name,
                          email: myLocation?.contact?.email,
                          phone: myLocation?.contact?.phone
                        });
                      }
                    })
                    .finally(() => {
                      Vue.set(
                        this.events[index].location,
                        "code",
                        locationCode
                      );
                    });
                }
              }

              this.onContainerInformationChange("events", this.events);
            }

            if (data.goods) {
              this.listGoods = data.goods;
              this.onContainerInformationChange("goods", this.listGoods);
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.goodsActiveTab = 1;
            this.goodsTabsKey++;
          });
      }
    },

    customsFlag() {
      this.getEventsBaseOnCustomsFlag();

      // update container after regenerated events
      this.onContainerInformationChange("events", this.events);
    },

    async isValidateContainers(newVal) {
      if (newVal) {
        let isValidateSuccess = await this.$refs.containersObserver.validate();

        if (!isValidateSuccess) {
          this.$emit("set-container-having-errors", this.containerIndex);
        }
      }
    }
  },

  computed: {
    isCustomsFlagImport() {
      return this.customsFlag?.toLowerCase() === "import";
    },

    editableEvents() {
      // The "events" array always contains only 4 elements.
      // Each element of "editableEvents" array is a flag to activate the editable
      // attribute for EventEditor according to each element of "events" array.
      // When ContainerEditor is not in Editing mode, we just return an array with all "true".
      var editableEvents = [true, true, true, true];
      if (this.isEditingOrder && this.events && this.events.length > 0) {
        for (let i = 3; i >= 0; i--) {
          if (
            this.events[i].state?.toLowerCase() == "ok" ||
            this.events[i].state?.toLowerCase() == "ko"
          ) {
            for (let j = 0; j <= i; j++) {
              editableEvents[j] = false;
            }
            break;
          }
        }
      }
      return editableEvents;
    }
  },

  methods: {
    setEventHavingErrors(index) {
      // find the first tab (which has the smallest index) that has errors
      if (this.firstEventHavingErrors !== null) {
        if (index < this.firstEventHavingErrors)
          this.firstEventHavingErrors = index;
      } else this.firstEventHavingErrors = index;

      this.eventActiveTab = this.firstEventHavingErrors;
      this.$emit(
        "change-event-active-tab",
        this.eventActiveTab,
        this.containerIndex
      );
    },

    setGoodsHavingErrors(index) {
      // find the first tab (which has the smallest index) that has errors
      if (this.firstGoodsHavingErrors !== null) {
        if (index < this.firstGoodsHavingErrors)
          this.firstGoodsHavingErrors = index;
      } else this.firstGoodsHavingErrors = index;

      // the first tab is already used to display 'Goods' label, so other tab for goods must be the goods index plus 1
      this.goodsActiveTab = this.firstGoodsHavingErrors + 1;
      this.$emit(
        "change-goods-active-tab",
        this.goodsActiveTab,
        this.containerIndex
      );
    },

    computeEvents() {
      // when user is editing order or creating order (and UI re-renders)
      if (this.propContainer.events) {
        let propEvents = this.propContainer.events.filter(e =>
          allowedEventsArray.includes(e.type)
        );
        let eventTypesArray =
          this.customsFlag === "import" ? importEventsArray : exportEventsArray;
        let simplifiedEventsList = [];

        // filter duplicate event in propEvents array
        for (let i = 0; i < 4; i++) {
          let existedOkOrKoEvent = propEvents.find(e => {
            return (
              e.type == eventTypesArray[i] &&
              (e.state == "OK" || e.state == "KO")
            );
          });

          // event will be through InProcess and then OK/ KO state, if event has OK/ KO state:
          if (existedOkOrKoEvent) {
            simplifiedEventsList.push(existedOkOrKoEvent);
          }
          // if event doesn't have OK/ KO state, then it only has InProcess state:
          else
            simplifiedEventsList.push(
              propEvents.find(e => e.type == eventTypesArray[i])
            );
        }

        this.events = simplifiedEventsList;
      }
      // when user is creating order for the first time (and there was no events information in the container object)
      else this.getEventsBaseOnCustomsFlag();

      this.events = _.orderBy(this.events, e =>
        allowedEventsArray.indexOf(e.type)
      );

      // we update events of customer order immediately with this.events after computing it
      let containerPayload = {
        id: this.containerIndex,
        field: "events",
        value: this.events
      };

      this.$emit("set-container-information", containerPayload);
    },

    getEventsBaseOnCustomsFlag() {
      if (this.customsFlag?.toLowerCase() === "import") {
        this.events = [
          {
            type: IMPORT_EVENT_LADING,
            location: {}
          },
          {
            type: IMPORT_EVENT_DELIVERY,
            location: {}
          },
          {
            type: IMPORT_EVENT_EMPTY_RELEASE,
            location: {}
          },
          {
            type: IMPORT_EVENT_EMPTY_RETURN,
            location: {}
          }
        ];
      } else {
        this.events = [
          {
            type: EXPORT_EVENT_EMPTY_RELEASE,
            location: {}
          },
          {
            type: EXPORT_EVENT_POSITIONING,
            location: {}
          },
          {
            type: EXPORT_EVENT_LADING,
            location: {}
          },
          {
            type: EXPORT_EVENT_DELIVERY,
            location: {}
          }
        ];
      }
    },

    onContainerInformationChange(field, value) {
      let containerPayload = {
        id: this.containerIndex,
        field,
        value
      };
      this.$emit("set-container-information", containerPayload);
    },

    onEventInformationChange(eventPayload) {
      let eventIndex = eventPayload.eventId;
      let updatedEvent = this.events[eventIndex];

      // update event
      if (
        eventPayload.field === "startDate" ||
        eventPayload.field === "endDate"
      ) {
        Vue.set(updatedEvent, eventPayload.field, eventPayload.value);

        //automatically update IMPORT_EVENT_EMPTY_RELEASE event's startDate to be the same with IMPORT_EVENT_DELIVERY event's startDate
        if (updatedEvent.type === IMPORT_EVENT_DELIVERY) {
          let e = this.events.find(x => x.type === IMPORT_EVENT_EMPTY_RELEASE);
          if (e) {
            Vue.set(e, "startDate", updatedEvent.startDate);
          }
        }

        // pass event date to next event to validate start date
        if (eventIndex < 3) {
          Vue.set(
            this.events[eventIndex + 1],
            "previousEventDate",
            updatedEvent.endDate ?? updatedEvent.startDate
          );
        }
      }

      //update event.location
      if (
        eventPayload.field === "code" ||
        eventPayload.field === "name" ||
        eventPayload.field === "address" ||
        eventPayload.field === "zipCode" ||
        eventPayload.field === "city" ||
        eventPayload.field === "country" ||
        eventPayload.field === "contactName" ||
        eventPayload.field === "email" ||
        eventPayload.field === "phone" ||
        eventPayload.field === "reference"
      ) {
        if (
          eventPayload.field === "code" ||
          eventPayload.field === "name" ||
          eventPayload.field === "reference"
        )
          Vue.set(
            updatedEvent.location,
            eventPayload.field,
            eventPayload.value
          );

        //automatically update IMPORT_EVENT_EMPTY_RELEASE or EXPORT_EVENT_LADING event's code and name to be the same with IMPORT_EVENT_DELIVERY or EXPORT_EVENT_POSITIONING event's code and name
        if (
          updatedEvent.type === IMPORT_EVENT_DELIVERY ||
          updatedEvent.type === EXPORT_EVENT_POSITIONING
        ) {
          let e = this.events.find(
            x =>
              x.type === IMPORT_EVENT_EMPTY_RELEASE ||
              x.type === EXPORT_EVENT_LADING
          );
          if (e) {
            Vue.set(e.location, "code", updatedEvent.location.code);
            Vue.set(e.location, "name", updatedEvent.location.name);
            Vue.set(e.location, "location", {
              ...updatedEvent.location.location
            });
            Vue.set(e.location, "contact", {
              ...updatedEvent.location.contact
            });
          }
        }

        //automatically update IMPORT_EVENT_DELIVERY or EXPORT_EVENT_POSITIONING event's code and name to be the same with IMPORT_EVENT_EMPTY_RELEASE or EXPORT_EVENT_LADING event's code and name
        if (
          updatedEvent.type === IMPORT_EVENT_EMPTY_RELEASE ||
          updatedEvent.type === EXPORT_EVENT_LADING
        ) {
          let e = this.events.find(
            x =>
              x.type === IMPORT_EVENT_DELIVERY ||
              x.type === EXPORT_EVENT_POSITIONING
          );
          if (e) {
            Vue.set(e.location, "code", updatedEvent.location.code);
            Vue.set(e.location, "name", updatedEvent.location.name);
            Vue.set(e.location, "location", {
              ...updatedEvent.location.location
            });
            Vue.set(e.location, "contact", {
              ...updatedEvent.location.contact
            });
          }
        }
      }

      //update event.location.location
      if (
        eventPayload.field === "address" ||
        eventPayload.field === "zipCode" ||
        eventPayload.field === "city" ||
        eventPayload.field === "country"
      ) {
        let location = updatedEvent.location.location ?? {};
        location[eventPayload.field] = eventPayload.value;
        Vue.set(updatedEvent.location, "location", { ...location });
      }

      //update event.location.contact
      if (eventPayload.field === "contactName") {
        let contact = updatedEvent.location.contact ?? {};
        contact["name"] = eventPayload.value;
        Vue.set(updatedEvent.location, "contact", { ...contact });
      }

      if (eventPayload.field === "email" || eventPayload.field === "phone") {
        let contact = updatedEvent.location.contact ?? {};
        contact[eventPayload.field] = eventPayload.value;
        Vue.set(updatedEvent.location, "contact", { ...contact });
      }

      let containerPayload = {
        id: this.containerIndex,
        field: "events",
        value: this.events
      };

      this.$emit("set-container-information", containerPayload);
    },

    onGoodsInformationChange(goodsPayload) {
      let goodsIndex = goodsPayload.goodsId;
      let updatedGoods = this.listGoods[goodsIndex];

      // update goods.hazardousClasses
      if (
        goodsPayload.field === "undgCode" ||
        goodsPayload.field === "imdgCode" ||
        goodsPayload.field === "packagingGroup"
      ) {
        let hazardousClasses = updatedGoods.hazardousClasses;
        if (!hazardousClasses || hazardousClasses.length == 0)
          hazardousClasses = [{}];
        hazardousClasses[0][goodsPayload.field] = goodsPayload.value;
        Vue.set(updatedGoods, "hazardousClasses", hazardousClasses);
      }
      // update goods
      else {
        Vue.set(updatedGoods, goodsPayload.field, goodsPayload.value);
      }

      let containerPayload = {
        id: this.containerIndex,
        field: "goods",
        value: this.listGoods
      };

      this.$emit("set-container-information", containerPayload);
    },

    addGoods() {
      this.listGoods.push({});

      this.goodsActiveTab = this.listGoods.length;

      this.goodsTabsKey++;
    },

    deleteGoods(index) {
      // if last tab is deleted, show the tab before it
      if (this.goodsActiveTab === this.listGoods.length) this.goodsActiveTab--;

      this.listGoods.splice(index, 1);

      this.goodsTabsKey++;
    }
  },

  created() {
    this.computeEvents();

    // container information are not modifiable as soon as an transport event (lading, delivery, ...), not the S)ONE event (ici, association, announce, ...)
    this.isEditable = !this.propContainer.events?.some(e => {
      let transportEvents = [...importEventsArray, ...exportEventsArray];
      return (
        transportEvents.some(x => x.toLowerCase() == e.type?.toLowerCase()) &&
        (e.state?.toLowerCase() == "ok" || e.state?.toLowerCase() == "ko")
      );
    });
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/_variables";

.dark-mode label:hover {
  border-color: #656565;
}

.dark-mode label.is-focused {
  color: $input-color;
}

.dark-mode span.is-static {
  background: $input-background-color;
  color: $input-color;
}
</style>
