<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>

<template>
  <div class="info-container card">
    <div class="columns pb-4">
      <div v-if="organization" class="column is-half">
        <div class="info">
          <div class="label">{{ $t("organization") }}</div>
          <div class="value">{{ name }}</div>
        </div>
        <div class="info">
          <div class="label">{{ $t("siret") }}</div>
          <div class="value">{{ siret }}</div>
        </div>
        <div class="info">
          <div class="label is-flex is-align-items-center">
            {{ $t("actorCode") }}
            <b-tooltip
              :label="$t('updateActorCode')"
              v-if="isOwner || $isSuperViewer"
            >
              <b-button
                v-if="!isValidatedActorCode"
                class="is-size-7 ml-4 is-light"
                icon-pack="fas"
                icon-left="edit"
                @click="openActorCodeModal()"
              />
            </b-tooltip>
            <ActorCodeModal
              v-model="isOpenActorCodeModal"
              :organization-id="organization.id"
              :actor-code="actorCode"
              :actor-code-state="actorCodeState"
              :name="name"
              :is-owner="isOwner"
              :hide-all-errors="true"
              @updated-actor-code="reloadData"
            />
          </div>
          <div class="is-flex is-align-items-center">
            <div v-if="actorCode">
              {{ actorCode }}
            </div>
            <div v-else>N/A</div>
            <ActorCodeState :actor-code-state="actorCodeState" />
          </div>
        </div>
      </div>
      <div v-else class="column is-half">
        <AddOrganizationForm />
      </div>

      <div
        v-if="!organization || isOwner"
        class="column is-half is-flex is-justify-content-end"
      >
        <InstructionInfo />
      </div>
    </div>

    <div v-if="organization && members && members.length > 0">
      <div class="label mb-4">{{ $t("member.title") }}</div>
      <div v-for="member in members" :key="member.id" class="list-item">
        <MemberInfo
          :organization-id="organization.id"
          :member="member"
          :is-owner="isOwner"
          @update-data="reloadData"
        />
      </div>
    </div>

    <div v-if="isOwner && organization" class="mt-3">
      <div class="label">
        {{ $t("invitation.addMember") }}
      </div>

      <ValidationObserver
        ref="invitationValidation"
        tag="div"
        class="field is-flex is-align-items-start is-justify-content-space-between mt-5"
      >
        <ValidationProvider
          rules="required|max:255|email"
          v-slot="{ errors }"
          name="email"
        >
          <b-field grouped>
            <b-field expanded label-position="on-border" class="is-full">
              <template #label>
                <label class="required-field">
                  {{ $t("invitation.email") }}
                </label>
              </template>

              <b-input v-model.trim="inviteEmail" expanded />

              <template #message>
                <p class="has-text-danger">
                  {{ errors[0] }}
                </p>
              </template>
            </b-field>

            <b-field :label="$t('invitation.role')" label-position="on-border">
              <RoleSelect :role.sync="inviteRole" />
            </b-field>
          </b-field>
        </ValidationProvider>

        <b-tooltip :label="$t('invitation.invite')">
          <b-button
            class="ml-3"
            icon-pack="fas"
            icon-left="paper-plane"
            type="is-primary"
            :loading="isSendingInvitation"
            @click="sendInvitation"
          />
        </b-tooltip>
      </ValidationObserver>
    </div>

    <div v-if="isOwner && organization" class="mt-6">
      <div class="label mb-5">{{ $t("invitation.title") }}</div>
      <div v-if="invitations && invitations.length > 0" class="">
        <div
          v-for="invitation in invitations"
          class="list-item"
          :key="invitation.id"
        >
          <InvitationInfo :invitation="invitation" @update-data="reloadData" />
        </div>
      </div>
      <div v-else>
        {{ $t("invitation.noRecord") }}
      </div>
    </div>
  </div>
</template>

<script>
import ActorCodeModal from "@/components/my-organization/ActorCodeModal";
import ActorCodeState from "@/components/my-organization/ActorCodeState";
import AddOrganizationForm from "./AddOrganizationForm.vue";
import InstructionInfo from "@/components/my-organization/InstructionInfo";
import InvitationInfo from "./InvitationInfo.vue";
import MemberInfo from "./MemberInfo.vue";
import RoleSelect from "./RoleSelect.vue";

import invitationApi from "@/repository/customer-order/invitations.api.js";
import UserRole from "@/plugins/sone-connect/roles";

export default {
  name: "OrganizationInfo",

  components: {
    InstructionInfo,
    MemberInfo,
    InvitationInfo,
    AddOrganizationForm,
    ActorCodeModal,
    ActorCodeState,
    RoleSelect
  },

  props: {
    organization: { type: Object, default: () => {} }
  },

  data() {
    return {
      inviteEmail: "",
      inviteRole: UserRole.Editor,
      isOpenActorCodeModal: false,
      isSendingInvitation: false
    };
  },

  computed: {
    name() {
      return this.organization?.name;
    },
    siret() {
      return this.organization?.siret;
    },
    actorCode() {
      return this.organization?.actorCode;
    },
    actorCodeState() {
      return this.organization?.actorCodeState;
    },
    createdAt() {
      return this.organization?.createdAt;
    },
    createdBy() {
      return this.organization?.createdBy;
    },
    modifiedAt() {
      return this.organization?.modifiedAt;
    },
    modifiedBy() {
      return this.organization?.modifiedBy;
    },
    members() {
      return this.organization?.members;
    },
    invitations() {
      return this.organization?.invitations;
    },
    isOwner() {
      return this.organization?.members.some(
        member =>
          member.id == this.$soneConnect.keycloak.tokenParsed.sub &&
          member.role == UserRole.Owner
      );
    },

    isValidatedActorCode() {
      return this.actorCodeState == "Valid";
    }
  },

  methods: {
    openActorCodeModal() {
      this.isOpenActorCodeModal = true;
    },

    // Events to update data
    reloadData() {
      this.$emit("reload-data", null);
    },

    resetData() {
      this.inviteEmail = "";
      this.inviteRole = UserRole.Owner;
    },

    // Send invitation with email
    async sendInvitation() {
      try {
        const validateResult = await this.$refs.invitationValidation.validate();
        if (!validateResult) return;
        this.isSendingInvitation = true;
        await invitationApi.sendInvitation({
          organizationId: this.organization.id,
          email: this.inviteEmail,
          role: this.inviteRole
        });
        await this.resetData();
        await this.reloadData();
        requestAnimationFrame(() => {
          this.$refs.invitationValidation.reset();
        });
      } finally {
        this.isSendingInvitation = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.info-container {
  padding: 24px 32px;
}

.info {
  padding-bottom: 32px;
}

.label {
  font-size: 18px;
  line-height: 0.8rem;
  font-weight: 700;
}

.list-item {
  border-bottom: 1px rgb(238, 238, 238) solid;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

@media screen and (max-width: 768px) {
  .info-container {
    padding: 24px;
  }
}

.list-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
</style>
