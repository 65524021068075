<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>

<template>
  <div class="column is-one-third p-2 wrapper">
    <div class="box is-flex columns is-align-items-center">
      <div class="column item-left">
        <i class="fas fa-user-circle fa-2x has-text-primary"></i>
      </div>
      <div class="item-right column">
        <div class="is-flex is-justify-content-space-between">
          <strong class="min-width is-word-wrap-break-word">
            {{ memberName }}
          </strong>
          <span class="tag is-primary">{{ $t("member.role." + role) }}</span>
        </div>
        <div class="is-word-wrap-break-word is-fullwidth">{{ email }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MemberItem",

  props: {
    memberName: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  margin: 0 0 1px 0;
  align-self: stretch;
}

.box {
  width: 100%;
}

.item-left {
  width: fit-content;
  flex: none;
}

.item-right {
  flex: 1;
  min-width: 0;
}

.min-width {
  min-width: 0;
}
</style>
