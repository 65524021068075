<template>
  <ValidationObserver>
    <ValidationProvider
      :rules="validationRules"
      v-slot="{ errors }"
      :name="validationName"
      :tag="validationTag"
      :class="validationClass"
      mode="lazy"
    >
      <b-tooltip
        :label="label"
        class="is-fullwidth"
        :triggers="['click']"
        :auto-close="['outside']"
        :active="canLabelTooltipActive"
      >
        <b-field ref="autocomplete" expanded :label-position="labelPosition">
          <template #label>
            <label
              id="autocomplete-label"
              :class="{
                'required-field': isRequired,
                'non-required-field': !isRequired
              }"
              class="mb-6"
              @click="canLabelTooltipActive = true"
            >
              {{ label }}
            </label>
          </template>

          <template #message v-if="!hideAllErrors">
            <p class="has-text-danger item-not-found-error">
              {{ errorMsg }}
            </p>
            <p v-if="!errorMsg" class="has-text-danger">
              {{ errors[0] }}
            </p>
          </template>

          <!-- autocomplete search -->
          <div class="relative-input">
            <b-autocomplete
              ref="b-autocomplete"
              :class="{
                'autocomplete-input': !isAutocompleteFieldExpanded,
                'is-uppercase': uppercaseInput
              }"
              v-model="inputValue"
              :placeholder="placeholder"
              :data="suggestions"
              :field="searchProperty"
              :loading="isLoading"
              @focus="onFocusInputField"
              @typing="getSuggestions"
              @select="onSelectSuggestion"
              @blur="onBlurInputField"
              @input="inputChanged"
              :icon-right-clickable="!disabled"
              :icon-right="inputValue && !disabled ? 'close-circle' : null"
              @icon-right-click="handleClear"
              @mouseenter.native="handleHover"
              @mouseleave.native="isBlurPrevented = false"
              @keydown.native.tab="isBlurPrevented = false"
              icon-pack="fas"
              :disabled="disabled"
              append-to-body
            >
              <template #default="{ option }">
                <div @mousedown="isBlurPrevented = true">
                  {{ option[searchProperty] }}
                  <span v-if="option[descriptionProperty]">
                    {{ ` - ${option[descriptionProperty]}` }}
                  </span>
                </div>
              </template>
            </b-autocomplete>

            <span
              class="tooltip"
              v-show="isBlurPrevented && inputValue && disabled && isOverflow"
            >
              {{ inputValue }}
            </span>
          </div>

          <!-- description -->
          <div class="relative-input" v-if="isDescriptionDisplayed">
            <span
              class="tooltip"
              v-show="
                isBlurPreventedDescription &&
                  description &&
                  isDescriptionOverflow
              "
            >
              {{ description }}
            </span>

            <b-input
              class="ml-1 description"
              disabled
              expanded
              ref="description"
              :value="description"
              @mouseenter.native="handleHoverDescription"
              @mouseleave.native="isBlurPreventedDescription = false"
            />
          </div>
        </b-field>
      </b-tooltip>
    </ValidationProvider>

    <!-- This element is used for prevent the CustomerOrderEditor from submitting data containing falsy values -->
    <ValidationProvider
      v-if="errorMsg"
      rules="required"
      :name="`autofield-${label}-error`"
    >
      <b-input type="hidden" />
    </ValidationProvider>
  </ValidationObserver>
</template>
<script>
import debounce from "lodash/debounce";

export default {
  name: "Autocomplete",

  props: {
    /**
     * initial input value.
     */
    value: String,
    /**
     * label of the autocomplete search
     */
    label: String,
    /**
     * placeholder inside input field.
     */
    placeholder: String,
    /**
     * indicate that the field is require
     */
    isRequired: {
      type: Boolean,
      default: false
    },
    /**
     * property of the object to use for searching.
     */
    searchProperty: {
      type: String,
      required: true
    },
    /**
     * max length of the input for searching.
     */
    maxLength: {
      type: Number,
      default: 9999
    },
    /**
     * valid input length if input length is fixed.
     */
    isFixedLength: {
      type: Boolean,
      default: false
    },
    /**
     * do we need to expand the autocomplete input field or not.
     */
    isAutocompleteFieldExpanded: {
      type: Boolean,
      default: false
    },
    /**
     * show description field or not.
     */
    isDescriptionDisplayed: {
      type: Boolean,
      default: true
    },
    /**
     * property of the object to use for display description.
     */
    descriptionProperty: {
      type: String,
      required: true
    },
    /**
     * The key to map automatically to right field name for validation message
     */
    validationName: {
      type: String,
      required: true
    },
    /**
     * custom tag property of ValidationProvider.
     */
    validationTag: {
      type: String,
      default: "span"
    },
    /**
     * custom class property of ValidationProvider.
     */
    validationClass: {
      type: String,
      default: ""
    },
    /**
     * position of b-field label.
     */
    labelPosition: {
      type: String,
      default: ""
    },
    /**
     * vee-validate rules
     */
    validationRules: [String, Object],
    /**
     * function to fetch a single data object.
     */
    fetchAsync: {
      type: Function,
      required: false
    },
    /**
     * function to fetch list of suggestions.
     */
    fetchAllAsync: {
      type: Function,
      default: null
    },
    /**
     * Query parameters when call API.
     */
    queryParameters: Object,
    /**
     * Query parameters when call API.
     */
    pathParameters: Object,
    /**
     * Query param to search with FetchAllAsync func, usually equals to SearchProperty.
     * Default value is "code"
     */
    searchQueryParameter: {
      type: String,
      default: "code"
    },
    /**
     * The component searchs results by StartsWith criteria by default.
     * Searching suffix is added to the end of the search string if an search API endpoint requires.
     */
    searchingSuffix: {
      type: String,
      default: "*"
    },
    /**
     * Set to TRUE to format the input string to be uppercase automatically
     */
    uppercaseInput: {
      type: Boolean,
      default: true
    },
    /**
     * Determine if this component is disabled
     */
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    /**
     * Prevent the component from automatically fetching data when the input value is changed
     * outside the component scope.
     */
    disabledFetchDataTriggeredFromOutside: {
      type: Boolean,
      default: false
    },
    /**
     * Hide errors of requests made by FetchAllAsync
     */
    hideFetchAllAsyncError: {
      type: Boolean,
      default: false
    },

    /**
     * Hide errors of requests made by FetchAsync
     */
    hideAllErrors: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      inputValue: this.value,
      description: "",
      suggestions: [],
      canLabelTooltipActive: false,
      isLoading: false,
      isFocusing: false,
      selectedDone: false,
      // variable to prevent trigger blur event when select an option from suggestions.
      isBlurPrevented: false,
      isBlurPreventedDescription: false,
      // functional error returned from API calls.
      errorMsg: null,
      // variable to check text is overflow or not.
      isOverflow: false,
      isDescriptionOverflow: false
    };
  },

  watch: {
    inputValue: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("input", newVal);
      }
    },

    description: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("input:description", newVal);
      }
    },

    errorMsg: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("input:error", newVal);
      }
    },

    value: function(newVal) {
      this.inputValue = newVal;
    },

    fetchAllAsync: function(newVal, oldVal) {
      // When the delegated func changes,
      // reset the inner variables that supports Autocomplete feature
      if (newVal != oldVal) {
        this.suggestions = [];
        this.inputValue = "";
        this.description = "";
      }
    },

    "$i18n.locale"() {
      if (this.errorMsg)
        // call fetchData function to get returned error in updated language
        this.fetchData(this.inputValue);
    }
  },

  methods: {
    /**
     * Get all suggestions with entered value.
     */
    getSuggestions: debounce(function(input) {
      if (this.uppercaseInput) {
        this.inputValue = input?.toUpperCase();
      }

      if (typeof this.fetchAllAsync !== "function") return;

      // API is not called if user entered nothing or invalid code.
      input = input?.trim();
      if (input?.length === 0 || input?.length > this.maxLength) {
        this.suggestions = [];
        this.errorMsg = null;
        return;
      }

      var searchPattern =
        input +
        (input.length === this.maxLength ? "" : this.searchingSuffix ?? ""); // padding * at the end of input to search by StartWith mode

      let queryParams = { ...this.queryParameters };
      queryParams[this.searchQueryParameter] = searchPattern;

      let pathParams = {
        ...this.pathParameters
      };

      let vm = this;
      vm.isLoading = true;
      vm.fetchAllAsync(pathParams, queryParams)
        .then(response => {
          vm.suggestions = response.data.items;
          vm.errorMsg = null;
        })
        .catch(error => {
          vm.suggestions = [];
          if (!this.hideFetchAllAsyncError)
            vm.errorMsg = error?.response?.data?.message;
        })
        .finally(() => {
          vm.isLoading = false;
        });
    }, 300),

    /**
     * Function to fetch a single data object.
     */
    fetchData: function(input) {
      if (!this.fetchAsync) return;

      // API is not called if user entered nothing or invalid code.
      input = input?.trim();
      if (
        !input ||
        (this.isFixedLength && input?.length !== this.maxLength) ||
        input?.length > this.maxLength
      ) {
        this.description = "";
        this.errorMsg = null;
        return;
      }

      let queryParams = {
        ...this.queryParameters
      };

      let pathParams = {
        code: input,
        ...this.pathParameters
      };

      let vm = this;
      vm.isLoading = true;
      vm.fetchAsync(pathParams, queryParams)
        .then(response => {
          vm.description = response.data[vm.descriptionProperty];
          vm.errorMsg = null;
        })
        .catch(error => {
          vm.description = "";
          vm.errorMsg = error?.response?.data?.message;
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },

    /**
     * Handler event when user blur the input field.
     */
    onBlurInputField: function() {
      this.isFocusing = false;
      if (this.isBlurPrevented) {
        this.isBlurPrevented = false;
        return;
      }

      let obj = this.suggestions.find(
        element => element[this.searchProperty] === this.inputValue
      );

      if (obj) {
        this.description = obj[this.descriptionProperty];
        this.$emit("select", obj);
      } else {
        this.fetchData(this.inputValue);
      }
    },

    /**
     * Handler when user focuses on the input field.
     */
    onFocusInputField: function() {
      this.isFocusing = true;
      this.canLabelTooltipActive = false;
    },

    /**
     * Fetching data when the input changed from outside
     */
    inputChanged: function() {
      if (this.disabledFetchDataTriggeredFromOutside) return;

      // This function does not run when user blur or select an option
      if (!this.isFocusing && !this.selectedDone) {
        let obj = this.suggestions.find(
          element => element[this.searchProperty] === this.inputValue
        );

        if (obj) {
          this.description = obj[this.descriptionProperty];
        } else {
          this.fetchData(this.inputValue);
        }
      }

      if (this.selectedDone) {
        this.selectedDone = false;
      }
    },

    /**
     * Function handle event when use choose an option from list of suggestions.
     */
    onSelectSuggestion: function(selectedItem) {
      if (selectedItem) {
        this.inputValue = selectedItem[this.searchProperty];
        this.description = selectedItem[this.descriptionProperty];
        this.$emit("select", selectedItem);
      }

      this.selectedDone = true;
    },

    /**
     * Update width of suggestion dropdown.
     */
    setDropdownWidth: function() {
      let fieldWidth = this.$refs["autocomplete"]?.$el?.clientWidth;
      let dropdown = this.$refs["b-autocomplete"]?.$refs["dropdown"];

      if (dropdown) {
        dropdown.style.width = `${fieldWidth}px`;
        dropdown.style.maxWidth = `${fieldWidth}px`;
      }
    },

    /**
     * Handle clear input value.
     */
    handleClear() {
      // Reset all data and set input.
      this.inputValue = null;
      this.description = null;
      this.errorMsg = null;
    },

    /**
     * Handle hover in main text.
     */
    handleHover() {
      this.isBlurPrevented = true;
      this.getOverflowStatus();
    },

    /**
     * Handle hover in description.
     */
    handleHoverDescription() {
      this.isBlurPreventedDescription = true;
      this.getDescriptionOverflowStatus();
    },

    /**
     * Get overflow input status.
     */
    getOverflowStatus() {
      let div = this.createDivWithAutoWidth(this.inputValue);

      this.isOverflow =
        div.offsetWidth >= this.$refs["b-autocomplete"]?.$el?.clientWidth - 24;
      div.remove();
    },

    /**
     * Get overflow input status
     */
    getDescriptionOverflowStatus() {
      if (this.isDescriptionDisplayed) {
        let div = this.createDivWithAutoWidth(this.description);

        this.isDescriptionOverflow =
          div.offsetWidth >= this.$refs["description"]?.$el?.clientWidth - 24;
        div.remove();
      }
    },

    /**
     * Create a div with auto width
     * @param {*} value - text in div.
     */
    createDivWithAutoWidth(value) {
      let div = document.createElement("div");
      div.style.display = "inline";
      div.style.width = "auto";
      div.style.visibility = "hidden";
      div.innerHTML = value;
      document.body.appendChild(div);
      return div;
    }
  },

  created: function() {
    // fetch data if the initial value is provided.
    if (this.value?.length > 0) {
      this.fetchData(this.value);
    }

    window.addEventListener("resize", this.setDropdownWidth);
  },

  mounted: function() {
    this.setDropdownWidth();
  }
};
</script>

<style>
.autocomplete-input {
  width: 9rem;
}

.autocomplete.is-uppercase input {
  text-transform: uppercase;
}

.description input {
  overflow: hidden;
  text-overflow: ellipsis;
}

.subject-update > .field > .autocomplete > .dropdown-menu > .dropdown-content {
  position: fixed;
  z-index: 100;
}

a.dropdown-item {
  padding: 0;
  padding-right: 0 !important;
}

a.dropdown-item > div {
  padding: 0.375rem 1rem;
}
</style>
