<i18n src="@/i18n/locales/customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/customer-order.fr.json"></i18n>

<template>
  <div class="px-3">
    <div class="label is-size-4 has-text-weight-normal">
      {{ $t("history.title") }}
    </div>
    <HorizontalTimeLine :items="getTimelineItems()" :is-history-view="true" />
  </div>
</template>

<script>
import HorizontalTimeLine from "@/components/common/timeline/HorizontalTimeLine";
export default {
  name: "OrderHistory",
  components: {
    HorizontalTimeLine
  },

  props: {
    histories: {
      required: true,
      type: Array
    }
  },

  methods: {
    /**
     * @typedef TimelineItem - The item containing strings to display in the timeline
     * @property {string} label - Label to display on the top of the timeline position
     * @property {string} title1 - The string display on the second line of the timeline position
     * @property {string} title2 - The string display on the third line of the timeline position
     * @property {string} title3 - The string display on the fourth line of the timeline position
     * @property {string} title4 - The string display on the fifth line of the timeline position
     * @property {string} details - The string display on the sixth line of the timeline position
     * /
    
    /**
     * Get the items to display in the timeline.
     * @returns {TimelineItem[]} Array of TimelineItem objects
     */
    getTimelineItems: function() {
      /**
       * @type {TimelineItem[]}
       */
      let res = [];

      for (let i = 0; i < this.histories.length; i++) {
        const currStatus = this.histories[i].status.toLowerCase();
        const prevStatus =
          i == 0 ? null : this.histories[i - 1].status.toLowerCase();

        let displayStatus = currStatus;

        // if the status is "Valid" and there is a previous "Valid" status, we consider it as an update
        if (currStatus == "valid") {
          let firstIndex = this.histories.findIndex(
            e => e.status.toLowerCase() == "valid"
          );
          if (firstIndex > -1 && firstIndex < i) displayStatus = "update";
        }

        // if both the current status and its previous status are "InProcess", "Accepted", "Cancelled" or "Refused"
        // we consider the current status as an update
        if (
          i > 0 &&
          ["inprocess", "accepted", "cancelled", "refused"].includes(
            currStatus
          ) &&
          prevStatus === currStatus
        )
          displayStatus = "update";

        const datetimeString = this.$d(
          new Date(this.histories[i].date),
          "shortWithoutTimeZone",
          this.$i18n.locale
        );
        const statusTitle = "status." + displayStatus;
        const actionDescription = this.getActionDescription(
          displayStatus,
          this.histories[i].action,
          this.histories[i].actor?.isCarrier
        );

        res.push({
          label: datetimeString,
          title1: statusTitle,
          title2: actionDescription,
          title3:
            this.histories[i].editor?.length > 0
              ? this.$t("by") + " " + this.histories[i].editor
              : "",
          title4: this.histories[i].comment,
          details: this.histories[i].details
        });
      }

      return res;
    },

    /**
     * Get the description of the history based on the order status and the history action.
     * @param orderStatus - Status of the order
     * @param historyAction - Action type of the history
     * @param isCarrier - True if the actor did the action is a carrier, false otherwise
     * @returns {string} The description of the action
     */
    getActionDescription: function(orderStatus, historyAction, isCarrier) {
      let action = historyAction?.toLowerCase();

      if (action == "update") {
        switch (orderStatus) {
          case "submitted":
            action = "updateContractorOrSone";
            break;

          case "rejected":
          case "inprocess":
            action = "updateInProcess";
            break;

          case "accepted":
            action = "updateAccepted";
            break;

          case "refused":
            action = "updateRefused";
            break;

          case "update":
            action = isCarrier ? "updateCarrier" : "updateContractorOrSone";
            break;

          default:
            action = "updateContractorOrSone";
        }
      } else if (action == "create") {
        action = orderStatus == "draft" ? "createDraft" : "create";
      }
      // if action is "create" or "delete", we don't need to modify it

      return action ? this.$i18n.t(`history.actions.${action}`) : "...";
    }
  },

  created() {
    this.histories = this.histories.sort((h1, h2) => {
      let d1 = new Date(h1.date);
      let d2 = new Date(h2.date);
      return d1.getTime() - d2.getTime();
    });
  }
};
</script>

<style scoped>
.step-details {
  margin-top: 10px;
}
</style>
