import { render, staticRenderFns } from "./OrganizationInfo.vue?vue&type=template&id=345c6da4&scoped=true&"
import script from "./OrganizationInfo.vue?vue&type=script&lang=js&"
export * from "./OrganizationInfo.vue?vue&type=script&lang=js&"
import style0 from "./OrganizationInfo.vue?vue&type=style&index=0&id=345c6da4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345c6da4",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/locales/my-organization.en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fmy-organization%2FOrganizationInfo.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/locales/my-organization.fr.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fmy-organization%2FOrganizationInfo.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports