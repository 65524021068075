<i18n src="@/i18n/locales/event.en.json"></i18n>
<i18n src="@/i18n/locales/event.fr.json"></i18n>

<template>
  <b-collapse animation="slide" :open="false" @open="loadPicturesOfIncident">
    <template #trigger="props">
      <span id="pictures-title">
        {{ $t("incidents.pictures") }}
      </span>
      <b-icon :icon="props.open ? 'caret-up' : 'caret-down'" size="is-small" />
    </template>

    <b-carousel-list
      class="is-shadowless"
      style="height: 60px; width: 240px;"
      :data="incident.pictures"
      :arrow-hover="false"
      :items-to-show="3"
      :has-drag="false"
    >
      <template #item="props">
        <b-image
          :class="{ 'is-clickable': !isLoadingPictures }"
          :src="!isLoadingPictures ? props.content : spinner"
          :title="
            props.fileName == 'error'
              ? $t('incidents.errorImage')
              : props.fileName
          "
          @click.native="showImageViewer(props.index)"
        />
      </template>
    </b-carousel-list>
  </b-collapse>
</template>

<script>
import errorImage from "@/assets/images/error.svg";
import spinner from "@/assets/images/loading.gif";
import ordersApi from "@/repository/customer-order/orders.api.js";

const MAX_RETRIES = 5;

export default {
  name: "PicturesCollapse",

  props: {
    incident: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      /**
       * The spinner image.
       */
      spinner,
      /**
       * Notifying if the pictures are loading or not.
       */
      isLoadingPictures: false,
      /**
       * Controlling if the image viewer is displayed or not.
       */
      isDisplayImageViewer: false
    };
  },

  computed: {
    /**
     * Master order ID.
     */
    orderIdentifier() {
      return this.$route.params.orderIdentifier;
    }
  },

  methods: {
    /**
     * Show the image viewer with the selected picture.
     * @param {number} picturceIndex Selected picture index.
     */
    showImageViewer: function(pictureIndex) {
      if (this.isLoadingPictures) return;

      this.$emit("show-image-viewer", this.incident.pictures, pictureIndex);
    },

    /**
     * Load the pictures of the incident.
     */
    async loadPicturesOfIncident() {
      if (this.isLoadingPictures) return;

      this.isLoadingPictures = true;

      try {
        for (let picture of this.incident.pictures) {
          if (!this.orderIdentifier) break;
          if (picture.content) continue;

          await this.fetchPictureWithRetry(picture);
        }
      } finally {
        this.isLoadingPictures = false;
      }
    },

    /**
     * Retrieve picture and handle if encouter error.
     * @param {Picture} picture Picture object.
     * @param {number} retries Number of time we retry.
     */
    async fetchPictureWithRetry(picture, retries = 0) {
      try {
        const response = await ordersApi.getIncidentPictureById(
          this.orderIdentifier,
          picture.pictureId
        );
        picture.content = "data:image/jpeg;base64," + response.data.content;
      } catch (error) {
        if (error.response && error.response.status === 429) {
          const retryAfter = error.response.headers["retry-after"];

          if (retryAfter) {
            const delay = parseInt(retryAfter, 10) * 1000;

            if (retries < MAX_RETRIES) {
              await new Promise(resolve => setTimeout(resolve, delay));
              await this.fetchPictureWithRetry(picture, retries + 1);
            } else {
              console.error(
                "Max retries reached for picture:",
                picture.pictureId
              );
            }
          }
        } else {
          console.error("Error fetching picture:", error);
        }

        // if we fail to retry getting image, show errorImage instead
        picture.content = errorImage;
        picture.fileName = "error";
      }
    }
  }
};
</script>
