<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>
<i18n src="@/i18n/locales/new-customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/new-customer-order.fr.json"></i18n>

<template>
  <div
    class="member-container is-flex is-justify-content-space-between is-fullwidth is-align-items-center"
  >
    <div class="left-container is-word-wrap-break-word">
      <div class="has-text-weight-medium">{{ userName }}</div>
      <div>{{ email }}</div>
    </div>
    <div
      class="right-container is-flex is-justify-content-space-between"
      v-if="isOwner && isShowEditAndDeleteButton"
    >
      <div
        v-if="isEditMode"
        class="is-flex is-fullwidth is-align-items-center is-justify-content-space-between"
      >
        <RoleSelect :role.sync="editedRole" :is-disable="!isEditMode" />
        <div>
          <b-tooltip :label="$t('actionButtons.cancel')">
            <b-button
              icon-pack="fas"
              icon-left="xmark"
              type="is-info"
              @click="setEditable(false)"
            />
          </b-tooltip>
          <b-tooltip :label="$t('actionButtons.confirm')">
            <b-button
              icon-pack="fas"
              icon-left="check"
              class="ml-2"
              type="is-primary"
              :loading="isUpdating"
              @click="updateRole"
            />
          </b-tooltip>
        </div>
      </div>
      <div
        v-else
        class="is-flex is-fullwidth is-align-items-center is-justify-content-space-between"
      >
        <div class="mr-2 has-text-weight-medium role">
          {{ $t("member.role." + editedRole) }}
        </div>
        <div>
          <b-tooltip :label="$t('edit')">
            <b-button
              icon-pack="fas"
              icon-left="edit"
              type="is-info"
              @click="setEditable(true)"
            />
          </b-tooltip>
          <b-tooltip :label="$t('delete')">
            <b-button
              icon-pack="fas"
              icon-left="trash"
              type="is-danger"
              class="ml-2"
              :loading="isDeleting"
              @click="deleteMember"
            />
          </b-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import organizationsApi from "@/repository/customer-order/organizations.api";
import RoleSelect from "./RoleSelect.vue";

export default {
  name: "MemberInfo",
  components: { RoleSelect },
  props: {
    organizationId: String,
    member: Object,
    isOwner: Boolean
  },
  data() {
    return {
      editedRole: "",
      isDeleting: false,
      isUpdating: false,
      isEditMode: false
    };
  },
  computed: {
    isShowEditAndDeleteButton() {
      return this.member.id != this.$soneConnect.keycloak.tokenParsed.sub;
    },
    role() {
      return this.member?.role;
    },
    userName() {
      return this.member?.firstName + " " + this.member?.lastName;
    },
    email() {
      return this.member?.email;
    }
  },
  mounted() {
    this.editedRole = this.member?.role;
  },
  methods: {
    // Set edit mode.
    setEditable(val) {
      this.isEditMode = val;
    },

    // Update role function.
    async updateRole() {
      if (this.editedRole == this.role) {
        this.isEditMode = false;
        return;
      }
      try {
        this.isUpdating = true;
        await organizationsApi.updateMemberOrganization(
          this.organizationId,
          this.member.id,
          {
            role: this.editedRole
          }
        );
        // Reload data and reset to no-edit mode.
        this.$emit("update-data", null);
        this.isEditMode = false;
      } finally {
        this.isUpdating = false;
      }
    },

    // Delete member function.
    async deleteMember() {
      this.$buefy.dialog.confirm({
        cancelText: this.$i18n.t("actionButtons.no"),
        confirmText: this.$i18n.t("actionButtons.sure"),
        message: this.$t("member.deleteContent"),
        type: "is-danger",
        iconPack: "fas",
        hasIcon: true,
        onConfirm: async () => {
          try {
            this.isDeleting = true;
            await organizationsApi.deleteMember(
              this.organizationId,
              this.member.id
            );
            this.$emit("update-data", null);
          } finally {
            this.isDeleting = false;
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$right-container-width: 280px;
.role {
  border: 1px #dfdfdf solid;
  padding: 7px 10px;
  border-radius: 4px;
  min-width: 140px;
}

@media screen and (max-width: 768px) {
  .member-container {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
  }
  .left-container {
    width: 100% !important;
  }
  .right-container {
    width: 100% !important;
    margin-top: 16px;
  }
}

.left-container {
  width: calc(100% - #{$right-container-width});
}
</style>
