<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>

<template>
  <div class="pr-5">
    <ValidationObserver ref="validationObserver" tag="div" class="field">
      <InputWithValidation
        rules="max:256|regex:^[a-zA-Z0-9_\s]*$"
        is-required
        :label="$t('organization')"
        validation-name="organization"
        v-model="organizationName"
      />

      <InputWithValidation
        rules="digits:14"
        is-required
        :label="$t('siret')"
        validation-name="siret"
        v-model="siret"
      />
    </ValidationObserver>

    <Autocomplete
      v-model="actorCode"
      :label="$t('actorCode')"
      validation-name="actorCode"
      :search-property="'code'"
      :max-length="32"
      :is-description-displayed="true"
      :label-position="'on-border'"
      :description-property="'name'"
      :fetch-all-async="getAllActorsAsync"
      :fetch-async="getActorAsync"
      :is-autocomplete-field-expanded="true"
      :hide-all-errors="true"
    />

    <p v-if="serverErrorMessage" class="has-text-danger pt-3">
      {{ serverErrorMessage }}
    </p>

    <div class="is-flex is-justify-content-end">
      <b-button
        type="is-primary"
        class="mt-4"
        :label="$t('create')"
        :loading="isWaitingSubmit"
        @click="submit"
      />
    </div>
  </div>
</template>

<script>
import organizationsApi from "@/repository/customer-order/organizations.api";
import actorsApi from "@/repository/referential/actors.api";
import Autocomplete from "@/components/common/Autocomplete.vue";
import InputWithValidation from "@/components/common/InputWithValidation.vue";

export default {
  name: "AddOrganizationForm",

  components: { Autocomplete, InputWithValidation },

  data() {
    return {
      organizationName: "",
      siret: "",
      actorCode: null,
      isWaitingSubmit: false,
      serverErrorMessage: null,
      getAllActorsAsync: actorsApi.getAll,
      getActorAsync: actorsApi.get
    };
  },

  methods: {
    async submit() {
      this.serverErrorMessage = null;
      const validateResult = await this.$refs.validationObserver.validate();
      if (!validateResult) return;
      const newData = {
        name: this.organizationName,
        siret: this.siret,
        actorCode: this.actorCode
      };
      this.isWaitingSubmit = true;
      try {
        await organizationsApi.createNewOrganization(newData);

        // Reload page to get new token with owner role.
        window.location.reload();
      } catch (err) {
        let errorData = err.response?.data;
        if (!errorData) {
          return;
        }

        this.serverErrorMessage = errorData.message;
      } finally {
        this.isWaitingSubmit = false;
      }
    }
  }
};
</script>
