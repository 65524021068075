import { extend } from "vee-validate";
import {
  digits,
  integer,
  is_not,
  length,
  max,
  regex,
  required
} from "vee-validate/dist/rules.umd.js";
import { localize } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import fr from "vee-validate/dist/locale/fr.json";
import enCustom from "@/i18n/locales/en.json";
import frCustom from "@/i18n/locales/fr.json";
import { enNames, frNames } from "@/validation/customNames";
import {
  email,
  date_from_today,
  custom_min_value,
  min_previous_event_date,
  custom_double
} from "@/validation/customRules";

import useI18n from "@/i18n/i18n.js";
const i18n = useI18n();

// EXTEND BUILT-IN VALIDATION RULES
extend("digits", digits);
extend("integer", integer);
extend("is_not", is_not);
extend("length", length);
extend("max", max);
extend("regex", regex);
extend("required", required);

// EXTEND CUSTOM VALIDATION RULES
// The localized message for the rule "double", "min_value" are reused from the built-in rule.
extend("double", custom_double);

extend("min_value", custom_min_value);

extend("date_from_today", {
  ...date_from_today,
  message: (field, values) => {
    values._field_ = i18n.t("validationMessages.date." + field);
    return toSentenceCase(i18n.t("validationMessages.dateFromToday", values));
  }
});

extend("date_eta_etd", {
  ...date_from_today,
  message: (field, values) => {
    values._field_ = i18n.t("validationMessages.date." + field);
    return toSentenceCase(i18n.t("validationMessages.dateEtaEtd", values));
  }
});

extend("email", email);

extend("min_previous_event_date", {
  ...min_previous_event_date,
  message: () => i18n.t("validationMessages.minPreviousEventDate")
});

extend("end_date_greater_than_start_date", {
  validate: (endDate, { target }) => {
    return !endDate || (target && endDate > target);
  },
  params: ["target"],
  message: () => i18n.t("validationMessages.endDateMustAfterStartDate")
});

localize({
  en: {
    messages: {
      ...en.messages,
      ...enCustom.validationMessages
    },
    names: {
      ...enNames
    }
  },
  fr: {
    messages: {
      ...fr.messages,
      ...frCustom.validationMessages
    },
    names: {
      ...frNames
    }
  }
});

function toSentenceCase(sentence) {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
}
