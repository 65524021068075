<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>

<template>
  <b-modal
    :active="value"
    :can-cancel="false"
    aria-modal
    :width="650"
    aria-role="dialog"
    @after-enter="mapActorCode()"
  >
    <div class="card">
      <b-loading v-model="isLoading" :is-full-page="false" />

      <header class="modal-card-head is-justify-content-space-between">
        <div class="modal-card-title-container">
          <p class="modal-card-title">{{ name }}</p>
        </div>
        <button type="button" class="delete" @click="closeModal"></button>
      </header>

      <div class="card-content has-text-weight-light">
        <ValidationObserver ref="validationObserver" tag="div" class="field">
          <Autocomplete
            v-model.trim="newActorCode"
            :validation-rules="validationRules"
            :label="$t('actorCode')"
            validation-name="actorCode"
            :search-property="'code'"
            :max-length="32"
            :is-description-displayed="true"
            :label-position="'on-border'"
            :description-property="'name'"
            :fetch-all-async="getAllActorsAsync"
            :fetch-async="getActorAsync"
            :is-autocomplete-field-expanded="true"
            :hide-all-errors="hideAllErrors"
          />
        </ValidationObserver>
      </div>

      <footer class="modal-card-foot is-justify-content-flex-end">
        <b-button v-if="isOwner" type="is-primary" @click="updateActorCode">
          {{ $t("actionButtons.confirm") }}
        </b-button>

        <div v-if="$isSuperViewer || $isAdmin">
          <b-button type="is-warning" @click="verifyActorCode(false)">
            {{ $t("actionButtons.invalidate") }}
          </b-button>
          <b-button type="is-primary" @click="verifyActorCode(true)">
            {{ $t("actionButtons.validate") }}
          </b-button>
        </div>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import organizationsApi from "@/repository/customer-order/organizations.api.js";
import actorsApi from "@/repository/referential/actors.api";
import notifier from "@/mixins/notifier";
import Autocomplete from "@/components/common/Autocomplete";

export default {
  name: "ActorCodeModal",

  components: {
    Autocomplete
  },

  mixins: [notifier],

  props: {
    organizationId: { type: String },
    name: { type: String },
    actorCode: { type: String },
    actorCodeState: { type: String },

    value: {
      type: Boolean,
      required: true,
      default: false
    },

    isOwner: { type: Boolean },

    hideAllErrors: {
      type: Boolean,
      default: false
    },

    validationRules: [String, Object]
  },

  data() {
    return {
      isLoading: false,
      newActorCode: null,
      getAllActorsAsync: actorsApi.getAll,
      getActorAsync: actorsApi.get
    };
  },

  methods: {
    closeModal() {
      this.$emit("input", false);
    },

    mapActorCode() {
      this.newActorCode = this.actorCode;
    },

    /**
     * Supporter can validate/ assign actor code for organization.
     * @param {Boolean} isActorCodeValid A flag indicating whether the actor code is valid or not.
     */
    async verifyActorCode(isActorCodeValid) {
      try {
        const validateResult = await this.$refs.validationObserver.validate();
        if (!validateResult && isActorCodeValid) return;

        let message;
        const newActorCode = {
          actorCodeState: isActorCodeValid ? "Valid" : "Invalid"
        };

        // if actorCode is validated, we have to send actorCode value along update payload
        if (isActorCodeValid) newActorCode.actorCode = this.newActorCode;
        else {
          // it's unreasonable to change inital actorCode value and then invalidate it
          if (this.newActorCode.toLowerCase() !== this.actorCode) {
            message = this.$t("notifyInvalidateNewActorCode");
            this.notifyError(message);
            return;
          }
          // cannot invalidate an invalid actorCode
          else if (this.actorCodeState == "Invalid") {
            message = this.$t("notifyInvalidateAlreadyInvalidActorCode");
            this.notifyError(message);
            return;
          }
        }

        this.isLoading = true;
        await organizationsApi
          .updateActorCode(this.organizationId, newActorCode)
          .then(() => {
            message = this.$t("notifyVerifyActorCode");
            this.notifySuccess(message);
            this.$emit("updated-actor-code", this.newActorCode);
            this.closeModal();
          });
      } finally {
        this.isLoading = false;
      }
    },

    /**
     * Update actor code
     */
    async updateActorCode() {
      const isChangedActorCode = this.newActorCode !== this.actorCode;
      if (isChangedActorCode) {
        try {
          const newActorCode = {
            actorCode: this.newActorCode,
            updateActorCode: this.newActorCode !== this.actorCode
          };

          this.isLoading = true;
          await organizationsApi
            .updateActorCode(this.organizationId, newActorCode)
            .then(() => {
              let message = this.$t("updateSucceed");
              this.notifySuccess(message);
              this.$emit("updated-actor-code", this.newActorCode);
              this.closeModal();
            });
        } finally {
          this.isLoading = false;
        }
      }
      return;
    }
  }
};
</script>

<style scoped>
.modal-card-title-container {
  display: flex;
  flex-wrap: wrap;
}

.modal-card-title {
  word-wrap: break-word;
  flex: 1;
}
</style>
