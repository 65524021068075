<i18n src="@/i18n/locales/invitation.en.json"></i18n>
<i18n src="@/i18n/locales/invitation.fr.json"></i18n>

<template>
  <div>
    <b-loading :is-full-page="true" v-model="isLoading" />
    <!-- Only show content if no loading.-->
    <div v-if="!isLoading">
      <div v-if="$isAuthenticated">
        <div v-if="isExpiredInvitation" class="has-text-centered">
          <div
            v-html="$t('expiredInvitation')"
            class="has-text-weight-medium"
          />
          <b-button
            @click="goHome"
            class="mt-4"
            :label="$t('invitation.goHome')"
            icon-pack="mdi"
            icon-left="home"
            type="is-primary"
          />
        </div>
        <!-- Show if the email of account match with invitation email. -->
        <div v-else-if="isExactAccount">
          <div
            class="has-text-centered is-size-5 has-text-weight-medium"
            v-html="invitationTitle"
          />
          <div class="has-text-centered mt-4 description">
            {{ inviterTitle }}
          </div>
          <!-- Show accept and cancel button if user is logged in. -->
          <div>
            <div class="is-flex is-justify-content-center mt-5">
              <b-button
                icon-pack="mdi"
                icon-left="close"
                :label="$t('invitation.cancel')"
                type="is-danger"
                @click="acceptInvitation(false)"
              />
              <b-button
                icon-pack="mdi"
                icon-left="check"
                :label="$t('invitation.accept')"
                type="is-primary"
                class="ml-3"
                @click="acceptInvitation(true)"
              />
            </div>
          </div>
          <!-- Otherwise, show login and signup form -->
        </div>
        <!-- If can't get information of invitation, show message to inform not found. -->
        <div v-else class="has-text-centered">
          <div v-if="!invitationInfo" class="has-text-weight-medium">
            {{ $t("invitationNotFound") }}
          </div>
          <div
            v-else
            v-html="notExactEmailDescription"
            class="has-text-weight-medium"
          />
          <b-button
            @click="goHome"
            class="mt-4"
            :label="$t('invitation.goHome')"
            icon-pack="mdi"
            icon-left="home"
            type="is-primary"
          />
        </div>
      </div>
      <div v-else>
        <div>
          <div
            class="has-text-centered is-size-5 has-text-weight-medium"
            v-html="invitationTitle"
          />

          <div class="has-text-centered mt-4 description">
            {{ inviterTitle }}
          </div>
          <div class="is-divider" />
          <div class="has-text-centered">
            {{ $t("alreadySignIn") }}
            <span class="ml-1 has-text-primary has-text-weight-medium">
              <a @click="logIn()">{{ $t("signIn") }}</a>
            </span>
          </div>
          <div class="is-divider" />
          <div class="has-text-centered">
            {{ $t("noAlreadySignIn") }}
            <span class="ml-1 has-text-primary has-text-weight-medium">
              <a :href="signUpUrl">{{ $t("signUp") }}</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import urlConfigurator from "@/mixins/urlConfigurator";
import invitationsApi from "@/repository/customer-order/invitations.api.js";
import moment from "moment";

export default {
  name: "InvitationForm",

  props: {
    userInfo: Object
  },

  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      userName: "",
      password: "",
      confirmation: "",
      invitationInfo: null,
      isLoading: false
    };
  },

  computed: {
    invitationTitle() {
      return this.$t("invitation.title", {
        organizationName:
          this.$route.query?.company ?? this.invitationInfo?.organizationName
      });
    },
    inviterTitle() {
      return this.$t("invitation.inviterTitle", {
        inviterName:
          this.$route.query?.inviter ??
          this.invitationInfo?.inviterFirstName +
            " " +
            this.invitationInfo?.inviterLastName
      });
    },
    // Assure this invitation has been accepted or refused yet.
    isExpiredInvitation() {
      return (
        this.invitationInfo?.acceptedAt ||
        this.invitationInfo?.refusedAt ||
        moment().isAfter(moment(this.invitationInfo?.expiredAt))
      );
    },
    // Assure this invitation is for current account or not
    isExactAccount() {
      return (
        this.invitationInfo?.email &&
        ((this.$isAuthenticated &&
          this.$soneConnect.keycloak?.tokenParsed?.email?.toLowerCase() ===
            this.invitationInfo?.email?.toLowerCase()) ||
          !this.$isAuthenticated)
      );
    },
    notExactEmailDescription() {
      return this.$t("invitation.notExactEmail", {
        email: this.invitationInfo?.email
      });
    }
  },

  async mounted() {
    // Load invitation data.
    if (this.$isAuthenticated) {
      try {
        this.isLoading = true;
        this.invitationInfo = await invitationsApi.getInvitationById(
          this.$route.params.invitationId
        );
        this.email = this.invitationInfo?.email;
      } finally {
        this.isLoading = false;
      }
    }
  },

  mixins: [urlConfigurator],

  methods: {
    /**
     * Back home route function.
     */
    goHome() {
      this.$router.push({ name: "Home" }).catch(() => {});
    },

    /**
     * Accept invitation.
     * @param {boolean} isAccept - indicate user accept or cancel invitation.
     */
    async acceptInvitation(isAccept) {
      try {
        if (this.$refs.validationObserver) {
          const validateResult = await this.$refs.validationObserver.validate();
          if (!validateResult) return;
        }

        this.isLoading = true;

        let patchData = { isAccept };

        // Call accept invitation api
        await invitationsApi.acceptInvitation(
          this.invitationInfo.id,
          patchData
        );

        // Route page depend on the case.
        if (this.$isAuthenticated)
          this.$router.push({ path: "/my-organization" });
        else {
          await this.logIn(true);
        }
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 18px !important;
  font-weight: 800 !important;
}
.field {
  min-width: 240px;
  max-width: 500px;
}

.is-divider {
  margin: 20px 0;
}

.description {
  font-size: 14px;
}
</style>
