import { PrivateCustomerOrderHttpClient } from "./customerOrderHTTPClient";
import "./../models.doc";

const END_POINT = "/organizations";

export default {
  /**
   * Get organization by id.
   * @param {string} organizationId - organization id.
   * @returns {Promise<OrganizationGetResponseProperties>}
   */
  getOrganizationById(organizationId) {
    return PrivateCustomerOrderHttpClient.httpClient
      .get(`${END_POINT}/${organizationId}`)
      .then(response => {
        return response.data;
      });
  },

  /**
   * Get all organizations
   * @param {string} [clientId] - ID of client representing for a SOGET product in Keycloak
   * @returns {Promise<HttpResponse<Array<OrganizationGetResponseProperties>>>}
   */
  getAllOrganizations(clientId) {
    return PrivateCustomerOrderHttpClient.httpClient.get(`${END_POINT}`, {
      clientId
    });
  },

  /**
   * Create new organization.
   * @param {OrganizationPost} data
   * @returns
   */
  createNewOrganization(data) {
    return PrivateCustomerOrderHttpClient.httpClientIgnoredDefaultInterceptors.post(
      `${END_POINT}`,
      data
    );
  },

  /**
   * Update member of organization.
   * @param {string} organizationId - organization id.
   * @param {string} memberId - member id.
   * @param {OrganizationMemberPatch} data - patch data.
   * @returns
   */
  updateMemberOrganization(organizationId, memberId, data) {
    return PrivateCustomerOrderHttpClient.httpClient.patch(
      `${END_POINT}/${organizationId}/users/${memberId}`,
      data
    );
  },

  /**
   * Update actor code.
   *
   * @param {string} organisationId organization identifier.
   * @param {object} payload payload to update
   * @param {string} payload.actorCode new actor code.
   * @param {string} payload.actorCodeState new actor code state.
   * @param {Boolean} payload.updateActorCode Flag to signal if ActorCode is being updated; only when the value is TRUE, ActorCode is therefore considered
   * @returns
   */
  updateActorCode(organisationId, payload) {
    return PrivateCustomerOrderHttpClient.httpClient.patch(
      `${END_POINT}/${organisationId}`,
      payload
    );
  },

  /**
   * Delete member.
   * @param {string} organizationId - Id of organization.
   * @param {string} userId - User id.
   * @returns
   */
  deleteMember(organizationId, userId) {
    return PrivateCustomerOrderHttpClient.httpClient.delete(
      `${END_POINT}/${organizationId}/users/${userId}`
    );
  }
};
