import { render, staticRenderFns } from "./OrderHistory.vue?vue&type=template&id=c44c9286&scoped=true&"
import script from "./OrderHistory.vue?vue&type=script&lang=js&"
export * from "./OrderHistory.vue?vue&type=script&lang=js&"
import style0 from "./OrderHistory.vue?vue&type=style&index=0&id=c44c9286&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c44c9286",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/locales/customer-order.en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fsuper-viewer%2FOrderHistory.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/locales/customer-order.fr.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fsuper-viewer%2FOrderHistory.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports