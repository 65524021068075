import actorsApi from "@/repository/referential/actors.api";

const actorNameCache = new Map();

export default {
  methods: {
    getActorName: function(actorCode) {
      if (!actorCode) return Promise.resolve(null);
      if (actorNameCache.has(actorCode))
        return Promise.resolve(actorNameCache.get(actorCode));

      let pathParams = {
        code: actorCode
      };

      return actorsApi
        .get(pathParams)
        .then(response => {
          actorNameCache.set(actorCode, response.data.name);
          return Promise.resolve(response.data.name);
        })
        .catch(() => {
          return Promise.resolve(actorCode);
        });
    }
  }
};
