import enCarrier from "@/i18n/locales/my-carrier.en.json";
import enComment from "@/i18n/locales/comment-info.en.json";
import enContainer from "@/i18n/locales/container-info.en.json";
import enEvent from "@/i18n/locales/event-info.en.json";
import enGeneral from "@/i18n/locales/general-info.en.json";
import enGood from "@/i18n/locales/goods-info.en.json";
import enOrganization from "@/i18n/locales/my-organization.en.json";
import enTranscodification from "@/i18n/locales/transcodification.en.json";
import enVoyage from "@/i18n/locales/voyage-info.en.json";

const enNames = {
  ...enCarrier.en,
  ...enComment.en,
  ...enContainer.en,
  ...enEvent.en,
  ...enGeneral.en,
  ...enGood.en,
  ...enOrganization.en,
  ...enTranscodification.en,
  ...enVoyage.en
};

import frCarrier from "@/i18n/locales/my-carrier.fr.json";
import frComment from "@/i18n/locales/comment-info.fr.json";
import frContainer from "@/i18n/locales/container-info.fr.json";
import frEvent from "@/i18n/locales/event-info.fr.json";
import frGeneral from "@/i18n/locales/general-info.fr.json";
import frGood from "@/i18n/locales/goods-info.fr.json";
import frOrganization from "@/i18n/locales/my-organization.fr.json";
import frTranscodification from "@/i18n/locales/transcodification.fr.json";
import frVoyage from "@/i18n/locales/voyage-info.fr.json";

const frNames = {
  ...frCarrier.fr,
  ...frComment.fr,
  ...frContainer.fr,
  ...frEvent.fr,
  ...frGeneral.fr,
  ...frGood.fr,
  ...frOrganization.fr,
  ...frTranscodification.fr,
  ...frVoyage.fr
};

export { enNames, frNames };
