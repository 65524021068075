<i18n src="@/i18n/locales/event.en.json"></i18n>
<i18n src="@/i18n/locales/event.fr.json"></i18n>

<template>
  <div class="px-3">
    <div class="label is-size-4 has-text-weight-normal">
      {{ $t("title") }}
    </div>
    <HorizontalTimeLine :items="timelineItems" is-event-timeline />
  </div>
</template>

<script>
import HorizontalTimeLine from "@/components/common/timeline/HorizontalTimeLine";

export default {
  name: "ContainerEvents",
  components: {
    HorizontalTimeLine
  },
  props: {
    timelineItems: {
      required: true,
      type: Array
    }
  }
};
</script>

<style scoped>
.step-details {
  margin-top: 10px;
}
</style>
