import { render, staticRenderFns } from "./RoleSelect.vue?vue&type=template&id=899b843e&"
import script from "./RoleSelect.vue?vue&type=script&lang=js&"
export * from "./RoleSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/locales/my-organization.en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fmy-organization%2FRoleSelect.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/locales/my-organization.fr.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fmy-organization%2FRoleSelect.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports