<i18n src="@/i18n/locales/new-customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/new-customer-order.fr.json"></i18n>

<template>
  <ValidationObserver ref="validationObserver">
    <b-loading v-model="isLoading"></b-loading>

    <div v-if="order" class="box" :key="$root.newOrderKey">
      <GeneralInfo
        :prop-order="order"
        :is-editing-order="isEditingOrder"
        @set-order-information="setOrderInformation"
      />

      <NewContainers
        :prop-containers="containers"
        :customs-flag="customsFlag"
        :refresh-ui="refreshUi"
        :is-validate-containers="isValidateContainers"
        @set-order-information="setOrderInformation"
        @refresh-complete="refreshUi = false"
        @change-is-validate-containers="isValidateContainers = false"
      />

      <!-- Just used for showing error when container array is empty -->
      <b-field v-if="isRequiredAtLeastOneContainer">
        <ValidationProvider rules="required" v-slot="{ invalid }">
          <b-input type="hidden" />
          <p class="has-text-danger" v-if="invalid">
            {{ $t("errors.insertAtLeastOneContainer") }}
          </p>
        </ValidationProvider>
      </b-field>

      <VoyageInfo
        :prop-voyage="order.voyage"
        :customs-flag="customsFlag"
        :reset-voyage-id="resetVoyageId"
        :has-one-container="hasOneContainer"
        @set-order-information="setOrderInformation"
      />

      <CommentInfo
        :prop-order="order"
        @set-order-information="setOrderInformation"
      />

      <ActionButtons
        :is-editing="isEditingOrder"
        :is-submitting="isSubmitting"
        :submit-handler="submitOrder"
      />
    </div>
    <VerificationPopup />
  </ValidationObserver>
</template>

<script>
import Vue from "vue";

import GeneralInfo from "@/components/customer-order/general-info/GeneralInfo.vue";
import NewContainers from "@/components/customer-order/container-info/NewContainers.vue";
import VoyageInfo from "@/components/customer-order/VoyageInfo.vue";
import CommentInfo from "@/components/customer-order/CommentInfo.vue";
import ActionButtons from "@/components/customer-order/ActionButtons.vue";
import VerificationPopup from "@/components/customer-order/VerificationPopup";

import customerOrdersApi from "@/repository/customer-order/orders.api";
import { scrollToError } from "@/mixins/scrollToError";
import { isEditable } from "@/helpers/orderHelper";
import notifier from "@/mixins/notifier";
import { isEmpty } from "lodash";

export default {
  components: {
    GeneralInfo,
    NewContainers,
    VoyageInfo,
    CommentInfo,
    ActionButtons,
    VerificationPopup
  },

  mixins: [scrollToError],

  data() {
    return {
      order: this.$route.params.orderIdentifier
        ? this.$route.params.orderNeedUpdate
        : {},
      containers: [], // this containers data is used only when editing order, not creating order

      canValidateOrder: false,
      isEditingOrder: this.$route.params.orderIdentifier !== undefined,
      isLoading: false,
      refreshUi: false,
      isSubmitting: false,

      isValidateContainers: false
    };
  },

  computed: {
    customsFlag() {
      return this.order?.customsFlag?.toLowerCase() ?? "import";
    },

    isRequiredAtLeastOneContainer() {
      return this.order?.containers?.length == 0;
    },

    // if all the elements in containers array is deleted, we reset the voyageId in VoyageInfo component to re-check new prefilled containers
    resetVoyageId() {
      return this.order?.containers?.length == 0;
    },

    // this variable is used to track if there's only one container tab in case user changes to a different container code in this tab
    hasOneContainer() {
      return this.order?.containers?.length == 1;
    }
  },

  watch: {
    //watch $root.newOrderKey in case user click on New order button on the header, it will help re-render UI and reset the data
    "$root.newOrderKey"() {
      this.order = {};
      this.containers = [];
    }
  },

  methods: {
    async submitOrder() {
      // Validate data
      const validationResult = await this.$refs.validationObserver.validate();

      if (!validationResult) {
        // scroll to containers section to display the right container/ event/ goods tabs that have error
        this.isValidateContainers = true;
        this.scrollToError();
        return;
      }

      this.isLoading = true;
      this.isSubmitting = true;

      try {
        if (this.isEditingOrder) {
          await customerOrdersApi.put(
            this.$route.params.orderIdentifier,
            this.order
          );

          this.$router.push({
            name: "SuperViewer",
            params: {
              orderIdentifier: this.$route.params.orderIdentifier
            }
          });
        } else {
          let submitResult = await customerOrdersApi.post(this.order);

          // display popup with successful message
          let message = this.$t("actionButtons.notifyNewCustomerOrder", {
            id: submitResult.data.id
          });
          notifier.methods.notifySuccess(message);

          this.$router.push({
            name: "SuperViewer",
            params: {
              orderIdentifier: submitResult.data.id
            }
          });
        }
      } finally {
        this.isLoading = false;
        this.isSubmitting = false;
      }
    },

    setOrderInformation(field, value) {
      Vue.set(this.order, field, value);
    }
  },

  async created() {
    // if it is in edit state, get the containers (of elementary orders if any) of the master order
    if (this.isEditingOrder) {
      this.isLoading = true;

      // If order is undefined or an empty object, and an ID was given to be edited
      if (!this.order || isEmpty(this.order)) {
        // Fetch the order
        this.order = await customerOrdersApi
          .getById(this.$route.params.orderIdentifier)
          .then(response => {
            return response.data;
          });
      }

      var children = await customerOrdersApi
        .getChildren(this.order.customerOrderId)
        .then(response => {
          return response.data;
        });

      if (children && children.length > 0) {
        for (let chilrenOrder of children) {
          if (chilrenOrder.type.toLowerCase() === "elementary")
            this.containers.push({
              ...chilrenOrder.container
            });
        }
      }

      if (this.order.container) {
        this.containers.push({
          ...this.order.container
        });
      }

      this.refreshUi = true;
      this.isLoading = false;

      // Redirect to superview when order is not editable
      this.order.containers = this.containers;
      if (!isEditable(this.order)) {
        this.$router.push({
          name: "SuperViewer",
          params: {
            orderIdentifier: this.$route.params.orderIdentifier
          }
        });
      }

      this.setOrderInformation("containers", this.containers);
    }
  }
};
</script>
