<i18n src="@/i18n/locales/general-info.en.json"></i18n>
<i18n src="@/i18n/locales/general-info.fr.json"></i18n>
<i18n src="@/i18n/locales/new-customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/new-customer-order.fr.json"></i18n>

<template>
  <b-field
    :label="
      propOrder.customerOrderId
        ? $t('order') + ' ' + propOrder.customerOrderId
        : $t('yourOrder')
    "
    label-position="on-border"
    class="large-label general-info"
    custom-class="transparent-label"
  >
    <div class="box">
      <div class="columns mt-1">
        <!-- Customs flag-->
        <div class="column ">
          <b-field :label="$t('customsFlag')" label-position="on-border">
            <b-select
              :disabled="isEditingOrder"
              v-model="customsFlag"
              @input="confirmChangeCustomsFlag()"
            >
              <option value="import">Import</option>
              <option value="export">Export</option>
            </b-select>
          </b-field>
        </div>

        <!-- Client reference -->
        <div class="column">
          <InputWithValidation
            :label="$t('clientReference')"
            validation-name="clientReference"
            v-model="clientReference"
            :disabled="isEditingOrder"
            @blur="
              $emit('set-order-information', 'clientReference', clientReference)
            "
          />
        </div>

        <!-- Transport order number -->
        <div class="column ">
          <InputWithValidation
            rules="max:252"
            :label="$t('transportOrderNumber')"
            validation-name="transportOrderNumber"
            v-model="transportOrderNumber"
            is-required
            :disabled="isEditingOrder"
            @blur="
              $emit(
                'set-order-information',
                'transportOrderNumber',
                transportOrderNumber
              )
            "
          />
        </div>

        <!-- Contractor code -->
        <div class="column" v-if="isEditingOrder">
          <Autocomplete
            disabled
            :value="contractor.code"
            :label="$t('contractorCode')"
            validation-name="contractorCode"
            :search-property="'code'"
            :is-description-displayed="false"
            :label-position="'on-border'"
            :description-property="'name'"
            :fetch-async="getActorAsync"
            :is-autocomplete-field-expanded="true"
          />
        </div>

        <!-- Contractor name-->
        <div class="column" v-if="isEditingOrder">
          <b-field :label="$t('contractorName')" label-position="on-border">
            <b-input disabled :value="contractorName" />
          </b-field>
        </div>

        <!-- Creation date -->
        <div class="column" v-show="isEditingOrder">
          <b-field :label="$t('creationDate')" label-position="on-border">
            <DateTimePicker v-model="creationDate" is-disabled />
          </b-field>
        </div>
      </div>

      <CarrierInfo
        :is-editing-order="isEditingOrder"
        :value="carrier"
        @set-order-information="onCarrierInformationChange"
      />
    </div>
  </b-field>
</template>

<script>
import Vue from "vue";

import Autocomplete from "@/components/common/Autocomplete";
import CarrierInfo from "@/components/customer-order/general-info/CarrierInfo.vue";
import DateTimePicker from "@/components/common/DateTimePicker.vue";
import InputWithValidation from "@/components/common/InputWithValidation.vue";

import actorsApi from "@/repository/referential/actors.api";

export default {
  name: "GeneralInfo",

  components: {
    Autocomplete,
    CarrierInfo,
    DateTimePicker,
    InputWithValidation
  },

  props: {
    // propOrder will have value when it's used for editing order purpose
    propOrder: {
      type: Object
    },

    isEditingOrder: {
      type: Boolean
    }
  },

  data() {
    return {
      getAllActorsAsync: actorsApi.getAll,
      getActorAsync: actorsApi.get,

      clientReference: this.propOrder.clientReference
        ? this.propOrder.clientReference
        : null,
      transportOrderNumber: this.propOrder.transportOrderNumber
        ? this.propOrder.transportOrderNumber
        : null,
      creationDate: this.propOrder.date
        ? new Date(this.propOrder.date)
        : new Date(),
      customsFlag: this.propOrder.customsFlag
        ? this.propOrder.customsFlag.toLowerCase()
        : this.$root.customsFlag,
      contractor: this.propOrder.contractor ? this.propOrder.contractor : {},
      contractorName: this.propOrder.contractor?.name
        ? this.propOrder.contractor.name
        : null,
      carrier: this.propOrder.carrier ? this.propOrder.carrier : {}
    };
  },

  watch: {
    contractorName(newVal) {
      Vue.set(this.contractor, "name", newVal);
    },
    "$root.customsFlag"() {
      if (!this.isEditingOrder) {
        this.customsFlag = this.$root.customsFlag;
        this.$emit("set-order-information", "customsFlag", this.customsFlag);
      }
    }
  },

  methods: {
    onCarrierInformationChange(carrier) {
      this.$emit("set-order-information", "carrier", carrier);
    },

    confirmChangeCustomsFlag() {
      this.$buefy.dialog.confirm({
        message: this.$i18n.t("updateCustomsFlag"),
        cancelText: this.$i18n.t("actionButtons.no"),
        confirmText: this.$i18n.t("actionButtons.sure"),
        type: "is-danger",
        iconPack: "fas",
        hasIcon: true,
        canCancel: true,
        onConfirm: () => {
          // If the user confirms the change : emit the update
          this.$emit("set-order-information", "customsFlag", this.customsFlag);
        },
        onCancel: () => {
          // If the user cancels the customs flag change : reset to the previous value
          this.customsFlag =
            this.customsFlag === "import" ? "export" : "import";
        }
      });
    }
  },

  mounted() {
    // these 2 fields are automatically initialized, so send these initialized information of order
    this.$emit("set-order-information", "creationDate", this.creationDate);
    this.$emit("set-order-information", "customsFlag", this.customsFlag);
  }
};
</script>

<style>
.general-info .box {
  box-shadow: 0 0, 0 0 !important;
  border-color: #d3d0d0;
}
</style>
