<template>
  <div
    class="horizontal-timeline"
    ref="horizontalTimeline"
    :class="{ 'horizontal-timeline-large': isPopUpDisplayed }"
    @click.self="isPopUpDisplayed = false"
  >
    <HorizontalTimeLineItem
      v-for="(item, index) in items"
      :key="index"
      :data="item"
      :is-event-timeline="isEventTimeline"
      :is-history-view="isHistoryView"
      :histories="item.details"
      @openLocationPopUp="isPopUpDisplayed = true"
    />
    <HorizontalTimeLineArrow :margin-top="isEventTimeline ? '24px' : null" />
  </div>
</template>

<script>
import HorizontalTimeLineArrow from "./HorizontalTimeLineArrow.vue";
import HorizontalTimeLineItem from "./HorizontalTimeLineItem.vue";

export default {
  name: "HorizontalTimeLine",

  data() {
    return {
      isPopUpDisplayed: false
    };
  },

  components: {
    HorizontalTimeLineArrow,
    HorizontalTimeLineItem
  },

  props: {
    items: {
      type: Array,
      required: true
    },
    isEventTimeline: Boolean,
    isHistoryView: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  methods: {
    handleClickOutside(event) {
      const timeline = this.$refs.horizontalTimeline;
      if (timeline && !timeline.contains(event.target)) {
        this.isPopUpDisplayed = false;
      }
    }
  },

  mounted() {
    document.addEventListener("click", this.handleClickOutside);

    this.$nextTick(() => {
      const timeline = this.$refs.horizontalTimeline;
      if (timeline) {
        timeline.scrollLeft = timeline.scrollWidth;
      }
    });
  },

  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>

<style scoped lang="scss">
.horizontal-timeline {
  width: 100%;
  height: 240px;
  overflow-x: scroll; /* Always show horizontal scrollbar */
  overflow-y: hidden; /* Disable vertical scrolling */
  white-space: nowrap;
  display: flex;
  align-items: flex-start;
}

.horizontal-timeline-large {
  height: 400px;
}
</style>
