import { render, staticRenderFns } from "./CarrierInfo.vue?vue&type=template&id=9d70212a&scoped=true&"
import script from "./CarrierInfo.vue?vue&type=script&lang=js&"
export * from "./CarrierInfo.vue?vue&type=script&lang=js&"
import style0 from "./CarrierInfo.vue?vue&type=style&index=0&id=9d70212a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d70212a",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/locales/general-info.en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fcustomer-order%2Fgeneral-info%2FCarrierInfo.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/locales/general-info.fr.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fcustomer-order%2Fgeneral-info%2FCarrierInfo.vue"
if (typeof block1 === 'function') block1(component)
import block2 from "@/i18n/locales/event-info.en.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fcustomer-order%2Fgeneral-info%2FCarrierInfo.vue"
if (typeof block2 === 'function') block2(component)
import block3 from "@/i18n/locales/event-info.fr.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fcustomer-order%2Fgeneral-info%2FCarrierInfo.vue"
if (typeof block3 === 'function') block3(component)

export default component.exports