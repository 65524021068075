<i18n src="@/i18n/locales/app.en.json"></i18n>
<i18n src="@/i18n/locales/app.fr.json"></i18n>
<i18n src="@/i18n/locales/transcodification.en.json"></i18n>
<i18n src="@/i18n/locales/transcodification.fr.json"></i18n>

<template>
  <b-modal
    :active="value"
    :can-cancel="false"
    has-modal-card
    aria-role="dialog"
    aria-label="Add a new actor"
    aria-modal
  >
    <div class="card">
      <!-- Modal title -->
      <header class="modal-card-head">
        <h1 class="modal-card-title">
          <span v-if="endpoint == 'actors'">{{ $t("titleCreateActor") }}</span>

          <span v-if="endpoint == 'locations'">{{
            $t("titleCreateLocation")
          }}</span>

          <span v-if="endpoint == 'container-types'">{{
            $t("titleCreateContainerType")
          }}</span>
        </h1>
        <button type="button" class="delete" @click="closeModal" />
      </header>

      <ValidationObserver ref="validationObserver" tag="div" class="field">
        <div class="card-content">
          <div class="box">
            <b-field grouped>
              <!-- Code field -->
              <Autocomplete
                v-model="code"
                label="Code"
                :is-autocomplete-field-expanded="true"
                :is-description-displayed="false"
                :is-required="true"
                :search-property="'code'"
                :description-property="descriptionProperty"
                :placeholder="$t('common.hintTypeSomething')"
                validation-tag="div"
                validation-class="field is-expanded"
                validation-name="code"
                :validation-rules="'required'"
                :fetch-all-async="getAllAsync"
                :fetch-async="getAsync"
                @input:description="value => (type = value)"
                @input:error="value => (autocompleteFieldError = value)"
              />

              <!-- Type field -->
              <ValidationProvider
                v-if="endpoint == 'actors'"
                rules="required"
                v-slot="{ errors }"
                name="type"
                tag="div"
                class="field is-expanded"
              >
                <b-field>
                  <template #label>
                    <LabelWithTooltip label="Type" :is-required="true" />
                  </template>
                  <b-input
                    v-model="type"
                    :placeholder="$t('common.hintTypeSomething')"
                    expanded
                  />
                  <template #message>
                    <p class="has-text-danger">
                      {{ errors[0] }}
                    </p>
                  </template>
                </b-field>
              </ValidationProvider>
            </b-field>
          </div>

          <div class="box">
            <!-- Add transcode here -->
            <b-field grouped label="Transcodifications">
              <b-field :label="$t('system')">
                <b-select
                  v-model="transcodeSystem"
                  :disabled="!isCapableOfAddingNewTranscode"
                >
                  <option
                    v-for="system in availableTranscodeSystems"
                    :key="system.code"
                    :value="system.code"
                  >
                    {{ system.name }}
                  </option>

                  <!-- This will show only when the select box is disabled to keep the view format. -->
                  <option
                    v-show="!isCapableOfAddingNewTranscode"
                    :value="transcodeSystems[0].code"
                  >
                    {{ transcodeSystems[0].name }}
                  </option>
                </b-select>
              </b-field>

              <ValidationProvider
                v-if="endpoint == 'container-types'"
                ref="transcodeValue"
                rules="length:4"
                v-slot="{ errors }"
                tag="div"
                name="value"
                class="field is-expanded"
              >
                <b-field :label="$t('value')" expanded>
                  <b-input
                    v-model="transcodeValue"
                    :placeholder="$t('common.hintTypeSomething')"
                    :disabled="!isCapableOfAddingNewTranscode"
                  />
                </b-field>
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>

              <b-field v-else :label="$t('value')" expanded>
                <b-input
                  v-model="transcodeValue"
                  :placeholder="$t('common.hintTypeSomething')"
                  :disabled="!isCapableOfAddingNewTranscode"
                />
              </b-field>
            </b-field>

            <div
              class="is-flex is-align-items-end is-justify-content-flex-end is-flex-wrap-wrap"
            >
              <b-button
                type="is-primary"
                @click="addTranscode"
                :disabled="!isCapableOfAddingNewTranscode"
              >
                {{ $t("addTranscode") }}
              </b-button>
            </div>

            <p>
              {{
                $tc(
                  "addedItems",
                  transcodes && transcodes.length ? transcodes.length : 0
                )
              }}
            </p>

            <!-- View added transcode list -->
            <b-field
              v-for="(transcode, index) in transcodes"
              :key="transcode.id"
              grouped
            >
              <b-field>
                <b-select :value="transcode.system" disabled>
                  <option
                    v-for="system in transcodeSystems"
                    :key="`${system.code}-show-value`"
                    :value="system.code"
                  >
                    {{ system.name }}
                  </option>
                </b-select>
              </b-field>

              <b-field expanded>
                <b-input
                  disabled
                  placeholder="type value"
                  :value="transcode.value"
                />
              </b-field>

              <b-field>
                <b-button type="is-danger" @click="removeTranscode(index)">
                  <b-icon pack="fas" icon="minus" />
                </b-button>
              </b-field>
            </b-field>
          </div>
        </div>
      </ValidationObserver>

      <div class="modal-card-foot is-justify-content-end">
        <b-button
          type="is-danger"
          :disabled="isWaitingSubmit"
          @click="closeModal"
        >
          {{ $t("cancel") }}
        </b-button>

        <b-button
          type="is-primary"
          :loading="isWaitingSubmit"
          :disabled="isWaitingSubmit"
          @click="submit"
        >
          <span>{{ $t("create") }}</span>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import transcodificationsApi from "@/repository/customer-order/transcodifications.api";

import referentialActorsApi from "@/repository/referential/actors.api.js";
import referentialLocationsApi from "@/repository/referential/locations.api.js";
import referentialContainerTypesApi from "@/repository/referential/containerTypes.api";

import notifier from "@/mixins/notifier";

import Autocomplete from "@/components/common/Autocomplete";
import LabelWithTooltip from "@/components/common/LabelWithTooltip";

const TRANSCODE_SYSTEMS = [
  {
    code: "GEDMOUV",
    name: "GedMouv"
  },
  {
    code: "AKANEA",
    name: "Akanea"
  }
];

export default {
  name: "SubjectModalAdd",

  components: {
    Autocomplete,
    LabelWithTooltip
  },

  mixins: [notifier],

  props: {
    /**
     * Boolean to indicate the modal is open or close.
     */
    value: {
      type: Boolean,
      required: true,
      default: false
    },

    endpoint: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      code: null,
      type: null,
      transcodes: [],
      transcodeSystem: "AKANEA",
      transcodeValue: null,
      availableTranscodeSystems: [],
      transcodeSystems: [
        {
          code: "AKANEA",
          name: "Akanea"
        }
      ],

      autocompleteFieldError: null,
      descriptionProperty: "",
      getAllAsync: null,
      getAsync: null,

      isWaitingSubmit: false
    };
  },

  mounted() {
    if (this.endpoint === process.env.VUE_APP_API_ACTORS_ENDPOINT) {
      this.getAllAsync = referentialActorsApi.getAll;
      this.getAsync = referentialActorsApi.get;
      this.descriptionProperty = "type";
      this.availableTranscodeSystems = [...TRANSCODE_SYSTEMS];
      this.transcodeSystems = TRANSCODE_SYSTEMS;
    }

    if (this.endpoint === process.env.VUE_APP_API_LOCATIONS_ENDPOINT) {
      this.getAllAsync = referentialLocationsApi.getAll;
      this.getAsync = referentialLocationsApi.get;
      this.descriptionProperty = "name";
      this.availableTranscodeSystems = [
        {
          code: "AKANEA",
          name: "Akanea"
        }
      ];
    }

    if (this.endpoint === process.env.VUE_APP_API_CONTAINER_TYPES_ENDPOINT) {
      this.getAllAsync = referentialContainerTypesApi.getAll;
      this.getAsync = referentialContainerTypesApi.get;
      this.descriptionProperty = "description";
      this.availableTranscodeSystems = [
        {
          code: "AKANEA",
          name: "Akanea"
        }
      ];
    }
  },

  computed: {
    isCapableOfAddingNewTranscode: function() {
      return this.availableTranscodeSystems.length > 0;
    }
  },

  methods: {
    closeModal: function() {
      this.resetModalData();
      this.$emit("input", false);
    },

    submit: async function() {
      const validateResult = await this.$refs.validationObserver.validate();

      if (!validateResult || this.autocompleteFieldError) return;

      const transcodifications = this.transcodes?.length
        ? this.transcodes.map(transcode => {
            return {
              system: transcode.system,
              value: transcode.value
            };
          })
        : [];

      const newData = {
        code: this.code,
        transcodifications
      };

      if (this.endpoint === process.env.VUE_APP_API_ACTORS_ENDPOINT) {
        newData["type"] = this.type;
      }

      this.isWaitingSubmit = true;

      try {
        const response = await transcodificationsApi.postSubject(
          this.endpoint,
          newData
        );

        // display popup with successful message
        let message = this.$t("actionButtons.notifyNewCustomerOrder", {
          id: response.data.id
        });

        this.notifySuccess(message);

        this.$emit("add-subject", newData);
        this.closeModal();
      } finally {
        this.isWaitingSubmit = false;
      }
    },

    addTranscode: async function() {
      if (this.endpoint === process.env.VUE_APP_API_CONTAINER_TYPES_ENDPOINT) {
        const validateResult = await this.$refs["transcodeValue"].validate();

        if (!validateResult.valid) return;
      }

      if (!this.transcodeValue?.trim()) return;

      const transcode = {
        id: Date.now(),
        system: this.transcodeSystem,
        value: this.transcodeValue
      };

      this.transcodes.push(transcode);

      this.availableTranscodeSystems = this.availableTranscodeSystems.filter(
        system => {
          return system.code !== this.transcodeSystem;
        }
      );

      this.resetTranscodeInputFieldsDefault();
    },

    removeTranscode: function(index) {
      this.transcodes.splice(index, 1);
      this.refreshAvailableTranscodeSystems();
      this.resetTranscodeInputFieldsDefault();
    },

    resetModalData: function() {
      this.code = null;
      this.type = null;
      this.transcodes = [];

      this.refreshAvailableTranscodeSystems();
      this.resetTranscodeInputFieldsDefault();
    },

    resetTranscodeInputFieldsDefault: function() {
      const transcodeSystems = this.availableTranscodeSystems.length
        ? this.availableTranscodeSystems
        : this.transcodeSystems;

      this.transcodeSystem = transcodeSystems[0].code;
      this.transcodeValue = null;
    },

    refreshAvailableTranscodeSystems: function() {
      this.availableTranscodeSystems = this.transcodeSystems.filter(system => {
        return (
          this.transcodes.findIndex(
            transcode => transcode?.system === system.code
          ) === -1
        );
      });
    }
  }
};
</script>
