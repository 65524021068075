import SoneConnect from ".";
import { UserToken } from "./model";
import UserRole from "./roles";

// Store the Vue instance where the plugin is installed
export let _Vue;

let instance;

export const useSOneConnect = () => {
  if (instance) return instance;

  instance = new SoneConnect();
  return instance;
};

export function install(Vue) {
  if (install.installed && Vue === _Vue) return;
  install.installed = true;

  _Vue = Vue;

  let soneConnect = useSOneConnect();
  soneConnect.userToken = new UserToken("", "", "");

  Object.defineProperty(Vue.prototype, "$soneConnect", {
    get() {
      return soneConnect;
    }
  });

  Object.defineProperty(Vue.prototype, "$isAuthenticated", {
    get() {
      return soneConnect.keycloak.authenticated;
    }
  });

  Object.defineProperty(Vue.prototype, "$isSuperViewer", {
    get() {
      return soneConnect.keycloak.hasResourceRole(
        UserRole.SuperViewer,
        window._config.sone_connect.CONFIG_AUTH_CLIENT_SMOUV_ID
      );
    }
  });

  Object.defineProperty(Vue.prototype, "$isAdmin", {
    get() {
      return soneConnect.keycloak.hasResourceRole(
        UserRole.Admin,
        window._config.sone_connect.CONFIG_AUTH_CLIENT_SMOUV_ID
      );
    }
  });

  Object.defineProperty(Vue.prototype, "$isOwner", {
    get() {
      return soneConnect.keycloak.hasResourceRole(
        UserRole.Owner,
        window._config.sone_connect.CONFIG_AUTH_CLIENT_SMOUV_ID
      );
    }
  });

  Object.defineProperty(Vue.prototype, "$isEditor", {
    get() {
      return soneConnect.keycloak.hasResourceRole(
        UserRole.Editor,
        window._config.sone_connect.CONFIG_AUTH_CLIENT_SMOUV_ID
      );
    }
  });

  Object.defineProperty(Vue.prototype, "$isUserNotAllowed", {
    get() {
      var clientSmouvRoles =
        soneConnect.keycloak.tokenParsed?.resource_access[
          window._config.sone_connect.CONFIG_AUTH_CLIENT_SMOUV_ID
        ];

      // A user is NOT ALLOWED to use app SMouv when they
      // + belong to an organization
      // + don't have any role in SMouv
      return soneConnect.hasOrganization && !clientSmouvRoles;
    }
  });

  Object.defineProperty(Vue.prototype, "$userProfile", {
    get() {
      return soneConnect.userProfile;
    }
  });

  Vue.mixin({
    methods: {
      async logIn(isRouteToHome = false) {
        let loadingIndex = this.$enableLoading();

        await this.$soneConnect
          .logIn(isRouteToHome)
          .then(() => {
            this.$disableLoading(loadingIndex);
          })
          .catch(() => {
            this.$disableLoading(loadingIndex);
          });
      },

      async signOut() {
        let loadingIndex = this.$enableLoading();

        await this.$soneConnect
          .logOut()
          .then(() => {
            this.$disableLoading(loadingIndex);
            this.$router.push({ path: "/connection" }).catch(() => {});
          })
          .catch(() => {
            this.$disableLoading(loadingIndex);
          });
      }
    }
  });
}
