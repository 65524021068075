<i18n src="@/i18n/locales/event-info.en.json"></i18n>
<i18n src="@/i18n/locales/event-info.fr.json"></i18n>

<template>
  <ValidationObserver ref="eventsObserver" :key="eventIndex">
    <div class="columns">
      <!-- Start date -->
      <div class="column">
        <ValidationProvider
          vid="startDateValidator"
          :rules="startDateValidationRules"
          v-slot="{ errors }"
          name="startDate"
          mode="lazy"
        >
          <b-field label-position="on-border">
            <template #label>
              {{ $t("startDate") }}
              <span v-if="isStartDateRequired" class="has-text-danger">*</span>
            </template>
            <DateTimePicker
              ref="startDate"
              :is-disabled="!isEditable"
              v-model="startDate"
              :position="'left'"
              @blur="onEventInformationChange('startDate', startDate)"
            />
            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- End date -->
      <div class="column">
        <ValidationProvider
          :rules="
            'end_date_greater_than_start_date:@startDateValidator|' +
              (isEditingOrder ? '' : 'date_from_today')
          "
          v-slot="{ errors }"
          name="endDate"
        >
          <b-field :label="$t('endDate')" label-position="on-border">
            <DateTimePicker
              ref="endDate"
              :is-disabled="!isEditable"
              v-model="endDate"
              @blur="onEventInformationChange('endDate', endDate)"
            />
            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>
          </b-field>
        </ValidationProvider>
      </div>

      <!-- Location reference -->
      <div class="column">
        <ValidationProvider
          rules="max:255"
          v-slot="{ errors }"
          name="reference"
        >
          <b-field :label="$t('reference')" label-position="on-border">
            <b-input
              ref="reference"
              v-model="reference"
              @input="onEventInformationChange('reference', reference)"
              :disabled="!isEditable"
            />
            <template #message>
              <p class="has-text-danger">
                {{ errors[0] }}
              </p>
            </template>
          </b-field>
        </ValidationProvider>
      </div>
    </div>

    <div class="columns">
      <!-- Location code -->
      <div class="column">
        <Autocomplete
          id="location-code"
          v-model="locationCode"
          :label="$t('locationCode')"
          :search-property="'code'"
          validation-name="locationCode"
          :validation-rules="locationCodeValidationRules"
          :is-required="isLocationCodeRequired"
          :max-length="255"
          :is-fixed-length="false"
          :is-description-displayed="true"
          :label-position="'on-border'"
          :description-property="'name'"
          :fetch-all-async="fetchAllLocationsAsync"
          :fetch-async="fetchLocationAsync"
          :searching-suffix="'*'"
          :is-autocomplete-field-expanded="true"
          :disabled="!isEditable"
          @select="value => handleSelectEvent(value, true, false)"
          @input:error="resetLocationDataFilledByAutocompleteCode()"
        />
      </div>

      <!-- Location name -->
      <Autocomplete
        id="location-name"
        v-if="enableMyLocations"
        class="column"
        v-model="locationName"
        :label="$t('locationName')"
        :search-property="'name'"
        validation-name="locationName"
        :validation-rules="locationNameValidationRules"
        :max-length="255"
        :is-required="isLocationNameRequired"
        :is-description-displayed="false"
        :label-position="'on-border'"
        :description-property="'code'"
        :fetch-all-async="getAllMyLocationsAsync"
        :is-autocomplete-field-expanded="true"
        search-query-parameter="name"
        :searching-suffix="''"
        :uppercase-input="false"
        :disabled="!isEditable"
        @select="value => handleSelectEvent(value, false, true)"
      />
      <div v-else class="column">
        <InputWithValidation
          :rules="locationNameValidationRules"
          :label="$t('locationName')"
          validation-name="locationName"
          :is-required="isLocationNameRequired"
          v-model="locationName"
          :disabled="!isEditable"
        />
      </div>
    </div>

    <div class="columns">
      <!-- Location address -->
      <div class="column">
        <InputWithValidation
          rules="max:255"
          :label="$t('address')"
          validation-name="address"
          v-model="address"
          @input="onEventInformationChange('address', address)"
          :disabled="!isEditable"
        />
      </div>

      <!-- Location zipcode -->
      <div class="column">
        <InputWithValidation
          rules="max:255"
          :label="$t('zipCode')"
          validation-name="zipCode"
          v-model="zipCode"
          @input="onEventInformationChange('zipCode', zipCode)"
          :disabled="!isEditable"
        />
      </div>

      <!-- Location city -->
      <div class="column">
        <InputWithValidation
          rules="max:255"
          :label="$t('city')"
          validation-name="city"
          v-model="city"
          @input="onEventInformationChange('city', city)"
          :disabled="!isEditable"
        />
      </div>

      <!-- Location country -->
      <div class="column">
        <div class="columns">
          <Autocomplete
            :disabled="!isEditable"
            v-model="country"
            :label="$t('country')"
            validation-name="country"
            :search-property="'alphaCode2'"
            :validation-rules="'length:2'"
            :max-length="2"
            :is-fixed-length="true"
            :is-description-displayed="true"
            :label-position="'on-border'"
            :description-property="'name'"
            class="column"
            :fetch-all-async="getAllCountriesAsync"
            :fetch-async="getCountryAsync"
            :is-autocomplete-field-expanded="true"
            @input="onEventInformationChange('country', country)"
          />
        </div>
      </div>
    </div>

    <b-field
      :label="$t('contact')"
      label-position="on-border"
      class="contact-info"
      custom-class="transparent-label"
    >
      <div class="columns box">
        <!-- Contact name -->
        <div class="column is-one-fifth-widescreen">
          <InputWithValidation
            rules="max:255"
            :label="$t('name')"
            validation-name="name"
            v-model="contactName"
            @input="onEventInformationChange('contactName', contactName)"
            :disabled="!isEditable"
          />
        </div>

        <!-- Contact email -->
        <div class="column is-one-fifth-widescreen">
          <InputWithValidation
            rules="max:255|email"
            :label="$t('email')"
            validation-name="email"
            v-model="email"
            @input="onEventInformationChange('email', email)"
            :disabled="!isEditable"
          />
        </div>

        <!-- Contact phone -->
        <div class="column is-one-fifth-widescreen">
          <InputWithValidation
            rules="max:255"
            :label="$t('phone')"
            validation-name="phone"
            v-model="phone"
            @input="onEventInformationChange('phone', phone)"
            :disabled="!isEditable"
          />
        </div>
      </div>
    </b-field>
  </ValidationObserver>
</template>

<script>
import DateTimePicker from "@/components/common/DateTimePicker.vue";
import Autocomplete from "@/components/common/Autocomplete";
import InputWithValidation from "@/components/common/InputWithValidation.vue";

import locationCodesApi from "@/repository/referential/locations.api.js";
import myLocationsApi from "@/repository/customer-order/myLocations.api.js";
import countriesApi from "@/repository/referential/countries.api.js";
import {
  importEventsArray,
  IMPORT_EVENT_LADING,
  IMPORT_EVENT_EMPTY_RETURN,
  EXPORT_EVENT_POSITIONING,
  EXPORT_EVENT_DELIVERY,
  EXPORT_EVENT_EMPTY_RELEASE
} from "@/helpers/constants.js";

export default {
  name: "EventEditor",

  components: {
    DateTimePicker,
    Autocomplete,
    InputWithValidation
  },

  props: {
    propEvent: {
      type: Object,
      default: null,
      required: true
    },

    eventIndex: {
      type: Number,
      required: true
    },

    isValidateContainers: {
      type: Boolean,
      default: false,
      require: true
    },

    isEditable: {
      type: Boolean,
      default: true,
      require: false
    }
  },

  data() {
    return {
      getAllCountriesAsync: countriesApi.getAll,
      getCountryAsync: countriesApi.get,
      getAllMyLocationsAsync: myLocationsApi.getAllMyLocations,
      fetchAllLocationsAsync: locationCodesApi.getAll,
      fetchLocationAsync: locationCodesApi.get,

      countryName: "",
      reference: this.propEvent.location.reference,

      startDate: this.propEvent.startDate
        ? new Date(this.propEvent.startDate)
        : null,
      endDate: this.propEvent.endDate ? new Date(this.propEvent.endDate) : null,

      isEditingOrder: this.$route.params?.orderIdentifier !== undefined,

      /**
       * Flag to determine if location information is filled by select an location code in auto-complete search field.
       */
      isLocationAutocompleteByCode: false,

      /**
       * Flag to determine if location information is filled by select an location name in auto-complete search field.
       */
      isLocationAutocompleteByName: false,

      enableMyLocations:
        window._config.app.CONFIG_ENABLE_MY_LOCATIONS_MANAGEMENT
    };
  },

  mounted: function() {
    // Add event when clicking tab in startDate
    this.$refs.startDate.$el.addEventListener(
      "keyup",
      e => {
        if (
          e.key == "Tab" &&
          this.$refs.startDate.$children[0].isFocused &&
          this.startDate
        ) {
          this.$refs.startDate.$children[0].toggle();
          this.$refs.endDate.$children[0].$children[0].focus();
        }
      },
      false
    );

    // Add event when clicking tab in endDate
    this.$refs.endDate.$el.addEventListener(
      "keyup",
      e => {
        if (
          e.key == "Tab" &&
          this.$refs.endDate.$children[0].isFocused &&
          this.endDate
        ) {
          this.$refs.endDate.$children[0].toggle();
          this.$refs.reference.focus();
        }
      },
      false
    );
  },

  unmounted() {
    // Remove all events before unmounting.
    this.$refs.startDate.$el.removeEventListener();
    this.$refs.endDate.$el.removeEventListener();
  },

  computed: {
    locationCode: {
      get() {
        return this.propEvent.location.code;
      },
      set(newVal) {
        // when user resets the location code field to empty
        // we should check if the other data (location name, contact, etc.) were filled by auto-complete search
        // if true then reset them to empty
        if (!newVal) {
          this.resetLocationDataFilledByAutocompleteCode();
        }

        this.onEventInformationChange("code", newVal);
      }
    },

    locationName: {
      get() {
        return this.propEvent.location.name;
      },
      set(newVal) {
        // when user resets the location name field to empty
        // we should check if the other data (location code, contact, etc.) were filled by auto-complete search
        // if true then reset them to empty
        if (!newVal) {
          this.resetLocationDataFilledByAutocompleteName();
        }

        this.onEventInformationChange("name", newVal);
      }
    },

    address: {
      get() {
        return this.propEvent.location.location?.address;
      },
      set(newVal) {
        this.onEventInformationChange("address", newVal);
      }
    },

    zipCode: {
      get() {
        return this.propEvent.location.location?.zipCode;
      },
      set(newVal) {
        this.onEventInformationChange("zipCode", newVal);
      }
    },

    city: {
      get() {
        return this.propEvent.location.location?.city;
      },
      set(newVal) {
        this.onEventInformationChange("city", newVal);
      }
    },

    country: {
      get() {
        return this.propEvent.location.location?.country;
      },
      set(newVal) {
        this.onEventInformationChange("country", newVal);
      }
    },

    contactName: {
      get() {
        return this.propEvent.location.contact?.name;
      },
      set(newVal) {
        this.onEventInformationChange("contactName", newVal);
      }
    },

    email: {
      get() {
        return this.propEvent.location.contact?.email;
      },
      set(newVal) {
        this.onEventInformationChange("email", newVal);
      }
    },

    phone: {
      get() {
        return this.propEvent.location.contact?.phone;
      },
      set(newVal) {
        this.onEventInformationChange("phone", newVal);
      }
    },

    isStartDateRequired() {
      return (
        importEventsArray.includes(this.propEvent.type) ||
        this.propEvent.type === EXPORT_EVENT_POSITIONING
      );
    },

    startDateValidationRules() {
      return `min_previous_event_date:${this.propEvent.previousEventDate?.valueOf()}|${
        this.isStartDateRequired ? "required|" : ""
      }${this.isEditingOrder ? "" : "date_from_today"}`;
    },

    isLocationCodeRequired() {
      return (
        this.propEvent.type === IMPORT_EVENT_LADING ||
        this.propEvent.type === EXPORT_EVENT_DELIVERY
      );
    },

    locationCodeValidationRules() {
      return `${this.isLocationCodeRequired ? "required|" : ""}max:255`;
    },

    isLocationNameRequired() {
      return (
        this.propEvent.type !== IMPORT_EVENT_EMPTY_RETURN &&
        this.propEvent.type !== EXPORT_EVENT_EMPTY_RELEASE
      );
    },

    locationNameValidationRules() {
      return this.propEvent.type === IMPORT_EVENT_EMPTY_RETURN ||
        this.propEvent.type === EXPORT_EVENT_EMPTY_RELEASE
        ? "max:255"
        : "required|max:255";
    }
  },

  watch: {
    // using deep watcher to update startDate when startDate of IMPORT_EVENT_EMPTY_RELEASE changed
    propEvent: {
      handler() {
        this.startDate = this.propEvent.startDate
          ? new Date(this.propEvent.startDate)
          : this.propEvent.startDate;
      },
      deep: true
    },

    async isValidateContainers(newVal) {
      if (newVal) {
        let isValidateSuccess = await this.$refs.eventsObserver.validate();

        if (!isValidateSuccess) {
          this.$emit("set-event-having-errors", this.eventIndex);
        }
      }
    }
  },

  methods: {
    onEventInformationChange(field, value) {
      let eventPayload = {
        eventId: this.eventIndex,
        field,
        value
      };

      this.$emit("set-event-information", eventPayload);
    },

    async handleSelectEvent(
      selectedItem,
      isAutocompleteByCode,
      isAutocompleteByName
    ) {
      // we have to update name and code here,
      // if we update inside the if condition, it will never get update if the variable is set to false
      this.locationCode = selectedItem?.code;
      this.locationName = selectedItem?.name;

      if (this.enableMyLocations) {
        this.isLocationAutocompleteByCode = isAutocompleteByCode;
        this.isLocationAutocompleteByName = isAutocompleteByName;

        try {
          // When user selects an item searched by S)One code, this item doesn't have MyLocation'id.
          // But because each MyLocation has one unique code,
          // we can use its code to search for a MyLocationSimple and get MyLocationSimple's id
          if (this.isLocationAutocompleteByCode)
            await myLocationsApi
              .getAllMyLocations(null, {
                code: this.locationCode,
                limit: 1
              })
              .then(response => {
                if (response.data.items.length > 0)
                  selectedItem.id = response.data.items[0].id;
              });

          const location = await myLocationsApi.getMyLocationById({
            id: selectedItem.id
          });

          if (location) {
            this.locationCode = location?.code;
            this.locationName = location?.name;
            this.setLocationInformation(location);
          }
        } catch (err) {
          // Hide notification
          const deleteItem = document.getElementsByClassName("notification");
          deleteItem[0]?.remove();
          this.setLocationInformation(null);
        }
      }
    },

    /**
     * Reset data that are filled by selection of LOCATION CODE auto-complete search.
     */
    resetLocationDataFilledByAutocompleteCode() {
      if (this.enableMyLocations && this.isLocationAutocompleteByCode) {
        this.locationName = null;
        this.setLocationInformation(null);
        this.isLocationAutocompleteByCode = false;
      }
    },

    /**
     * Reset data that are filled by selection of LOCATION NAME auto-complete search.
     */
    resetLocationDataFilledByAutocompleteName() {
      if (this.enableMyLocations && this.isLocationAutocompleteByName) {
        this.locationCode = null;
        this.setLocationInformation(null);
        this.isLocationAutocompleteByName = false;
      }
    },

    setLocationInformation(location) {
      this.address = location?.address;
      this.zipCode = location?.postalCode;
      this.city = location?.city;
      this.country = location?.country;

      this.contactName = location?.contact?.name;
      this.email = location?.contact?.email;
      this.phone = location?.contact?.phone;
    }
  }
};
</script>

<style>
.contact-info .box {
  box-shadow: 0 0, 0 0 !important;
  border-color: #e1e1e1 !important;
}

.contact-info .columns.box {
  margin: 0;
  padding-top: 0.5rem;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  padding-bottom: 0;
}

.datepicker > .dropdown > .dropdown-menu {
  left: auto !important;
  right: 0;
  width: 360px;
}

.border-separate {
  border-width: 0 0 1px 0;
  border-color: #d6d6d6;
  border-style: solid;
  padding-bottom: 6px;
}
</style>
