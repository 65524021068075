<i18n src="@/i18n/locales/customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/customer-order.fr.json"></i18n>
<i18n src="@/i18n/locales/header.en.json"></i18n>
<i18n src="@/i18n/locales/header.fr.json"></i18n>

<template>
  <b-modal
    :active="isActiveModal"
    :can-cancel="false"
    aria-modal
    has-modal-card
    scroll="keep"
  >
    <div class="card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-weight-bold has-text-primary">
          {{ getTitle }}
        </p>
      </header>
      <div class="card-content">
        <h3 v-html="getContent" class="has-text-weight-bold"></h3>
      </div>
      <footer class="modal-card-foot" style="justify-content: flex-end;">
        <b-button type="is-primary" @click="signOut()" v-if="$isUserNotAllowed">
          {{ $t("signOut") }}</b-button
        >
        <b-button
          type="is-primary"
          @click="closeModal"
          v-if="isVerificationInProgress"
        >
          {{ $t("goToMyOrganization") }}</b-button
        >
      </footer>
    </div>
  </b-modal>
</template>

<script>
import organizationsApi from "@/repository/customer-order/organizations.api";

export default {
  name: "VerificationPopup",

  data() {
    return {
      isActiveModal: false,
      isOrganizationRequired: false,
      isVerificationInProgress: false,
      organization: null
    };
  },

  computed: {
    getTitle() {
      if (this.$isUserNotAllowed)
        return this.$t("verificationPopup.notAllowUser.title");
      else if (this.isOrganizationRequired) {
        return this.$t("verificationPopup.organizationRequired.title");
      } else if (this.isVerificationInProgress) {
        return this.$t("verificationPopup.verificationInProgress.title");
      } else {
        return "";
      }
    },
    getContent() {
      if (this.$isUserNotAllowed)
        return this.$t("verificationPopup.notAllowUser.content");
      else if (this.isOrganizationRequired) {
        return this.$t("verificationPopup.organizationRequired.content", {
          myOrganizationRoute: "/my-organization"
        });
      } else if (this.isVerificationInProgress) {
        return this.$t("verificationPopup.verificationInProgress.content");
      } else {
        return "";
      }
    }
  },

  mounted() {
    if (this.$isUserNotAllowed) this.isActiveModal = true;
    else this.checkUserBelongToOrganization();
  },

  methods: {
    closeModal() {
      this.$router.push({ name: "MyOrganization" });
    },

    /**
     * Check user belong to organization
     */
    async checkUserBelongToOrganization() {
      try {
        const response = await organizationsApi.getAllOrganizations();

        if (response.data?.length > 0) {
          this.organization = response.data[0];
          this.isVerificationInProgress =
            this.organization.actorCodeState !== "Valid";
        } else {
          this.isOrganizationRequired = true;
        }

        // Active verification modal.
        this.isActiveModal =
          !(this.$isAdmin || this.$isSuperViewer) &&
          (this.isOrganizationRequired || this.isVerificationInProgress);
      } catch {
        () => {};
      }
    }
  }
};
</script>
