<i18n src="@/i18n/locales/customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/customer-order.fr.json"></i18n>

<template>
  <b-modal
    :active="value"
    :can-cancel="false"
    has-modal-card
    class="modal"
    id="historyModal"
    aria-modal
    v-if="value"
  >
    <div class="card">
      <header class="modal-card-head">
        <h1 class="modal-card-title mr-6">
          <span>{{ $t("changeHistoryDetails") }}</span>
        </h1>
        <button type="button" class="delete" @click="closeModal" />
      </header>

      <div class="card-content">
        <ul class="first-node">
          <HistoryDetailsNode
            v-for="(item, index) in transformedHistory.children"
            :key="index"
            :node="item"
          />
        </ul>
      </div>
    </div>
  </b-modal>
</template>

<script>
import HistoryDetailsNode from "./HistoryDetailsNode.vue";

export default {
  name: "HistoryDetailsDialog",
  components: { HistoryDetailsNode },
  props: {
    /**
     * Boolean to indicate the modal is open or close.
     */
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    /**
     * History detailed
     * @type HistoryDetail[]
     */
    histories: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    /**
     * Transformed history
     */
    transformedHistory() {
      let transformedArray = this.transformArray(this.histories);
      return this.clearActions(transformedArray);
    }
  },
  methods: {
    /**
     * Transform the detailed histories from array to the tree structure
     * @param {HistoryDetail[]} data - History detail records
     * @returns {HistoryDetailNode} A tree containing HistoryDetails
     */
    transformArray: function(data) {
      data = this.reorderFields(data);
      /**
       * @type HistoryDetailNode
       */
      const root = {
        label: "root",
        type: "object",
        children: [],
        parentLabel: null
      };

      data.forEach(item => {
        const path = item.fieldName.split(".");
        let current = root;

        path.forEach((part, index) => {
          const arrayMatch = part.match(/(\w+)\[(.+?)\]/); // Accept number and string
          let node;

          // If the current node is array
          if (arrayMatch) {
            // eslint-disable-next-line no-unused-vars
            const [_, key, idx] = arrayMatch;
            node = current.children.find(
              child => child.label === this.lowercaseFirstLetter(key)
            );
            // Check node is existed in tree, if not add node
            if (!node) {
              node = {
                label: this.lowercaseFirstLetter(key),
                type: "array",
                children: [],
                parentLabel: current?.label
              };
              current.children.push(node);
            }

            let childNode = node.children.find(child => {
              if (node.label === "events") {
                return child.label === idx;
              }
              return child.label === idx && child.action === item.type;
            });

            if (!childNode) {
              childNode = {
                label: idx,
                action: item.type,
                type: "object",
                children: [],
                parentLabel: node?.label
              };
              node.children.push(childNode);
            }

            current = childNode;
          } else {
            // If not array, add node with object type
            node = current.children.find(
              child => child.label === this.lowercaseFirstLetter(part)
            );

            if (!node) {
              node = {
                label: this.lowercaseFirstLetter(part),
                type: "object",
                action: item.type,
                children: [],
                parentLabel: current?.label
              };
              current.children.push(node);
            }

            current = node;
          }

          // Check the primitive node and assign value for it
          if (index === path.length - 1) {
            current.action = item.type;
            if (item.oldValue !== undefined) current.oldValue = item.oldValue;
            if (item.newValue !== undefined) current.newValue = item.newValue;
            if (
              current.children.length === 0 &&
              (current.oldValue !== undefined || current.newValue !== undefined)
            ) {
              current.type = "primitive";
            }
            current.fieldType = item.fieldType;
          }
        });
      });

      this.setObjectActions(root);

      return root;
    },

    /**
     * Clear descendant actions by node.
     * @param {HistoryDetailNode} node - node
     */
    clearDescendantActions(node) {
      if (node.action) {
        node.action = null;
      }
      if (node.children) {
        node.children.forEach(this.clearDescendantActions);
      }
    },

    /**
     * Helper function to clear `action` for all descendants
     * @param {HistoryDetailNode} node - node
     */
    clearActions(node) {
      if (node.action === "Added" || node.action === "Removed") {
        node.children.forEach(this.clearDescendantActions);
      } else {
        if (node.children) {
          node.children.forEach(this.clearActions);
        }
      }
      return node;
    },

    /**
     * Reorder fields before using.
     * @param array
     */
    reorderFields(array) {
      const orders = {
        generalOrder: [
          "Comment",
          "ContractTermsInformation",
          "InstructionsInformation",
          "PriceInformation"
        ],
        dateOrder: ["State", "StartDate", "EndDate", "Reference"],
        goodsOrder: ["].Type", "].Quantity"],
        locationOrder: ["Location.Code", "Location.Name"],
        hazardousOrder: ["UndgCode", "ImdgCode", "PackagingGroup"],
        generalHuInfoOrder: [
          "HandlingUnit.Reference",
          "HandlingUnit.Rank",
          "HandlingUnit.SPI",
          "HandlingUnit.FreightAgent",
          "HandlingUnit.BillOfLadingNumber",
          "HandlingUnit.Type",
          "HandlingUnit.CustomsInformation",
          "HandlingUnit.Comment",
          "HandlingUnit.GrossWeight",
          "HandlingUnit.Tare",
          "HandlingUnit.IsOog",
          "HandlingUnit.HeightOffset",
          "HandlingUnit.LengthOffset",
          "HandlingUnit.WidthOffset",
          "HandlingUnit.IsReefer",
          "HandlingUnit.SetPoint",
          "HandlingUnit.IsHazardous"
        ],
        voyageOrder: [
          "Voyage.MeansName",
          "Voyage.FreightAgent",
          "Voyage.Company",
          "Voyage.Eta",
          "Voyage.Etd",
          "Voyage.ClosingCustoms",
          "Voyage.ClosingDangerous",
          "Voyage.ClosingDelivery"
        ]
      };

      // Sort helper function based on custom order
      const sortByCustomOrder = (fields, customOrder) => {
        return fields.sort((a, b) => {
          const aIndex = customOrder.findIndex(key =>
            a.fieldName.includes(key)
          );
          const bIndex = customOrder.findIndex(key =>
            b.fieldName.includes(key)
          );
          return aIndex - bIndex;
        });
      };

      // Generic function to sort fields based on group
      const getSortedFields = (orderName, isEqual = false) => {
        const customOrder = orders[orderName];
        return sortByCustomOrder(
          array.filter(field =>
            customOrder.some(key =>
              isEqual ? field.fieldName === key : field.fieldName.includes(key)
            )
          ),
          customOrder
        );
      };

      // Sort fields for each group
      const generalFields = getSortedFields("generalOrder", true);
      const dateFields = getSortedFields("dateOrder");
      const locationFields = getSortedFields("locationOrder");
      const goodsFields = getSortedFields("goodsOrder");
      const hazardousFields = getSortedFields("hazardousOrder");
      const generalHuInfoFields = getSortedFields("generalHuInfoOrder", true);
      const voyageFields = getSortedFields("voyageOrder", true);

      // Other fields (optional)
      const otherFields = array.filter(
        field =>
          ![
            ...orders.dateOrder,
            ...orders.locationOrder,
            ...orders.hazardousOrder,
            ...orders.goodsOrder
          ].some(key => field.fieldName.includes(key)) &&
          ![
            ...orders.generalOrder,
            ...orders.generalHuInfoOrder,
            ...orders.voyageOrder
          ].some(key => field.fieldName === key)
      );

      // Concatenate the ordered groups
      const reorderedList = [
        ...generalFields,
        ...generalHuInfoFields,
        ...dateFields,
        ...locationFields,
        ...hazardousFields,
        ...voyageFields,
        ...goodsFields,
        ...otherFields
      ];

      return reorderedList;
    },

    /**
     * Adjust the action of each HistoryDetailsNode based on their children's actions.
     * Action of HandlingUnit node is always considered as "Edited".
     * @param {HistoryDetailsNode} node
     */
    setObjectActions: function(node) {
      if (node.type === "object" || node.type === "array") {
        node.children.forEach(this.setObjectActions);

        const hasEditedChild = node.children.some(
          child => child.action === "Edited" && child.type === "primitive"
        );

        if (
          hasEditedChild &&
          node.type === "object" &&
          node.action === undefined
        ) {
          node.action = "Edited";
        }

        const hasAllAddedChild = node.children.every(
          child => child.action === "Added" && child.type === "primitive"
        );
        if (
          hasAllAddedChild &&
          node.type === "object" &&
          node.action === undefined
        ) {
          node.action = "Added";
        }

        const hasAllRemovedChild = node.children.every(
          child => child.action === "Removed" && child.type === "primitive"
        );
        if (
          hasAllRemovedChild &&
          node.type === "object" &&
          node.action === undefined
        ) {
          node.action = "Removed";
        }
        if (
          (node.type === "object" && node.action === undefined) ||
          node.label === "handlingUnit"
        )
          node.action = "Edited";
      }
    },

    /**
     * Lower case the first letter of string (label)
     * @param string
     */
    lowercaseFirstLetter: function(string) {
      // Check if the string is fully uppercase (likely an acronym)
      if (string === string.toUpperCase()) {
        return string.toLowerCase(); // Convert the whole string to lowercase if it's an acronym
      }

      // Otherwise, convert only the first character to lowercase
      return string.charAt(0).toLowerCase() + string.slice(1);
    },

    /**
     * Close modal
     */
    closeModal: function() {
      this.$emit("input", false);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables.scss";

.card-content {
  overflow-y: auto;
  max-height: calc(100vh - 150px); /* Ensure the content section can scroll */
}

/* .first-node will be affected by card-content default spacing, so no need to add more margin from HistoryDetailsNode's margin*/
.first-node {
  margin-top: -$margin-history-node;
}

.modal-card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 50px);
}

@media screen and (min-width: 769px) {
  .card-content {
    min-width: 480px !important;
  }
}

@media screen and (max-width: 768px) {
  .card-content {
    padding: 20px 10px 10px !important;
  }
}
</style>
