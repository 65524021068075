<i18n src="@/i18n/locales/customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/customer-order.fr.json"></i18n>

<template>
  <section class="superviewer-container">
    <b-loading v-model="isLoading" :is-full-page="false" />
    <div v-if="order">
      <InfoGlobal
        :order-id="order.customerOrderId"
        :reference="order.transportOrderNumber"
        :client-reference="order.clientReference"
        :customs-flag="order.customsFlag"
        :date="order.date"
        :contractor="order.contractor"
        :carrier="order.carrier"
      />
      <InfoAdditional
        :voyage="order.voyage"
        :isImport="isImport"
        :comments="order.comment"
        :contractTermsInformation="order.contractTermsInformation"
        :instructionsInformation="order.instructionsInformation"
        :priceInformation="order.priceInformation"
      />
      <ContainerBoard
        :containers="containers"
        :isImport="isImport"
        :history-key="key"
      />
      <ActionButtons :prop-order="order" :key="key" />
    </div>
    <VerificationPopup />
  </section>
</template>

<script>
import InfoGlobal from "@/components/super-viewer/InfoGlobal.vue";
import InfoAdditional from "@/components/super-viewer/InfoAdditional.vue";
import ContainerBoard from "@/components/super-viewer/ContainersBoard.vue";
import ActionButtons from "@/components/super-viewer/ActionButtons.vue";
import VerificationPopup from "@/components/customer-order/VerificationPopup";

import customerOrdersApi from "@/repository/customer-order/orders.api";
import { camelCase } from "lodash";

export default {
  name: "SuperViewer",
  components: {
    InfoGlobal,
    InfoAdditional,
    ContainerBoard,
    ActionButtons,
    VerificationPopup
  },
  provide() {
    const appData = {};

    Object.defineProperty(appData, "masterOrder", {
      get: () => this.order
    });

    return {
      appData
    };
  },
  data() {
    return {
      order: null,
      children: [],
      isLoading: false,
      key: 0
    };
  },
  computed: {
    orderId: function() {
      return this.$route.params.orderIdentifier;
    },

    /**
     * Containing information of containers and its order history which have direct relationship with it.
     * Literally, if the master order is multi-container, histories of elementary order are enclosed,
     * in contrast, if the master order is mono-container, histories of master order are enclosed.
     */
    containers: function() {
      const containers = [];

      if (this.children && this.children.length > 0) {
        for (let elementaryOrder of this.children) {
          containers.push({
            container: elementaryOrder.container,
            histories: elementaryOrder.histories
          });
        }
      } else if (this.order.container) {
        containers.push({
          container: this.order.container,
          histories: this.order.histories
        });
      }
      return containers;
    },

    isImport: function() {
      return this.order?.customsFlag?.toUpperCase() === "IMPORT";
    }
  },

  methods: {
    fetchTransportOrder: function(orderId) {
      return customerOrdersApi
        .getById(orderId)
        .then(response => {
          return response.data;
        })
        .catch(err => {
          this.$router.push({
            name: "NotFound",
            params: { error: err.response.data.message }
          });
        });
    },

    /**
     * Append comment of child orders to parent
     */
    combinedComment: async function(parent) {
      let children = await this.fetchTransportOrderChildren(
        parent.customerOrderId
      );
      if (this.isMultiContainerOrder(parent)) {
        this.children = children;
      }

      children.forEach(child => {
        child.histories.forEach(childHistory => {
          if (childHistory.comment)
            parent.histories.forEach(parentHistory => {
              if (parentHistory.status == childHistory.status)
                if (parentHistory.comment)
                  parentHistory.comment +=
                    ";" +
                    this.$t("type." + camelCase(child.type)) +
                    ":;" +
                    childHistory.comment;
                else
                  parentHistory.comment =
                    this.$t("type." + camelCase(child.type)) +
                    ":;" +
                    childHistory.comment;
              this.key++;
            });
        });
      });
    },

    fetchTransportOrderChildren: function(masterOrderId) {
      return customerOrdersApi.getChildren(masterOrderId).then(response => {
        return response.data;
      });
    },

    isCarrier: function(determiningActor) {
      if (determiningActor?.code && this.order.carrier.code)
        return determiningActor?.code == this.order.carrier.code;
      else if (determiningActor?.name && this.order.carrier.name)
        return determiningActor?.name == this.order.carrier.name;
    },

    /**
     * Indicates the order is multi-container order or not.
     * @param order
     */
    isMultiContainerOrder: function(order) {
      return order.type?.toUpperCase() === "MASTER" && !order.container;
    }
  },
  async created() {
    try {
      this.isLoading = true;
      if (this.$route.params.orderObject)
        this.order = this.$route.params.orderObject;
      else this.order = await this.fetchTransportOrder(this.orderId);
      await this.combinedComment(this.order);

      this.order.histories = this.order?.histories.map(history => {
        if (!history.actor) history.actor = { isCarrier: false };
        else history.actor.isCarrier = this.isCarrier(history.actor);
        return history;
      });

      if (this.isMultiContainerOrder(this.order)) {
        if (!this.children) {
          this.children = await this.fetchTransportOrderChildren(this.orderId);
        }

        for (let child of this.children) {
          await this.combinedComment(child);
          child.histories.map(history => {
            if (!history.actor) history.actor = { isCarrier: false };
            else history.actor.isCarrier = this.isCarrier(history.actor);
            return history;
          });

          // if it's a multi container order, then link all containers to master.
          this.order.containers ??= [];
          this.order.containers.push(child.container);
        }
      }

      // rerender action buttons
      this.key++;
    } finally {
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
.superviewer-container {
  position: relative;
}
</style>
