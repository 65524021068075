<i18n src="@/i18n/locales/location.en.json"></i18n>
<i18n src="@/i18n/locales/location.fr.json"></i18n>
<i18n src="@/i18n/locales/contact.en.json"></i18n>
<i18n src="@/i18n/locales/contact.fr.json"></i18n>
<i18n src="@/i18n/locales/event.en.json"></i18n>
<i18n src="@/i18n/locales/event.fr.json"></i18n>
<i18n src="@/i18n/locales/customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/customer-order.fr.json"></i18n>

<template>
  <div class="is-inline-block">
    <div class="location-name" v-if="data.location">
      <!-- we don't use append-to-body in b-tooltip here, it's will break app width and height in responsive mode -->
      <b-tooltip
        ref="tooltip"
        class="timeline-tooltip"
        :active="canTooltipActive"
        type="is-white"
        position="is-bottom"
        size="is-large"
        multilined
        square
        :triggers="['click']"
        :auto-close="['outside']"
      >
        <div
          class="label is-clickable horizontal-timeline-item-location-label"
          :class="{ 'label-warning': hasIncidents }"
        >
          <span
            v-if="data.location.code || data.location.name"
            class="location-name-value"
          >
            {{ data.location.code ? data.location.code : data.location.name }}
          </span>
          <span v-else>{{ $t("unknownLocation") }}</span>
        </div>

        <template #content>
          <div class="content-container">
            <table class="table is-fullwidth has-text-left">
              <tbody>
                <tr>
                  <th>{{ $t("locationCode") }}</th>
                  <td>{{ data.location.code }}</td>
                </tr>
                <tr>
                  <th>{{ $t("locationName") }}</th>
                  <td>{{ data.location.name }}</td>
                </tr>
                <tr>
                  <th>{{ $t("reserves") }}</th>
                  <td>{{ data.location.reserves }}</td>
                </tr>
                <tr>
                  <th>{{ $t("reference") }}</th>
                  <td>{{ data.location.reference }}</td>
                </tr>
                <tr>
                  <th>{{ $t("address") }}</th>
                  <td>{{ address }}</td>
                </tr>
                <tr>
                  <th>{{ $t("zipCode") }}</th>
                  <td>{{ zipCode }}</td>
                </tr>
                <tr>
                  <th>{{ $t("city") }}</th>
                  <td>{{ city }}</td>
                </tr>
                <tr>
                  <th>{{ $t("country") }}</th>
                  <td>{{ country }}</td>
                </tr>
                <tr>
                  <th>{{ $t("contact") }}</th>
                  <td>{{ contactName }}</td>
                </tr>
                <tr>
                  <th>{{ $t("email") }}</th>
                  <td>{{ contactEmail }}</td>
                </tr>
                <tr>
                  <th>{{ $t("phone") }}</th>
                  <td>{{ contactPhone }}</td>
                </tr>
                <tr v-if="hasIncidents">
                  <td colspan="2">
                    <b-collapse animation="slide" :open="false">
                      <template #trigger="props">
                        <div class="level" role="button">
                          <div class="level-left">
                            <div class="level-item">
                              <span class="table-th">
                                {{ $t("incidents.title") }}
                              </span>
                            </div>
                          </div>

                          <div class="level-right">
                            <div class="level-item">
                              <b-icon
                                :icon="
                                  props.open ? 'chevron-up' : 'chevron-down'
                                "
                                size="is-small"
                              />
                            </div>
                          </div>
                        </div>
                      </template>

                      <div
                        v-for="(incident, key) in incidents"
                        :key="incident.type + key"
                        class="mb-2"
                        test-id="incident-element"
                      >
                        <div>
                          {{ key + 1 }}.
                          {{ $t(`incidents.type.${incident.type}`) }}
                        </div>
                        <div>
                          {{ $t("comment") }}:
                          <i>{{ incident.comment }}</i>
                        </div>

                        <PicturesCollapse
                          v-if="
                            incident.pictures && incident.pictures.length > 0
                          "
                          :incident="incident"
                          @show-image-viewer="showImageViewer"
                        />
                      </div>
                    </b-collapse>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </b-tooltip>
    </div>

    <!-- Image viewer -->
    <!-- we don't put Image viewer in PicturesCollapse component, because PicturesCollapse is inside b-tooltip, which is immediately hidden when user sees Image Viewer -->
    <b-carousel
      v-if="isDisplayImageViewer"
      class="image-viewer"
      :autoplay="false"
      :overlay="true"
      :value="selectedPictureIndex"
      :indicator-inside="false"
    >
      <b-carousel-item v-for="(picture, key) in pictures" :key="key">
        <b-image custom-class="image-viewer-img" :src="picture.content" />
      </b-carousel-item>

      <span @click="closeImageViewer" class="modal-close is-large"></span>
    </b-carousel>

    <div class="horizontal-timeline-item-container">
      <div
        class="overlay has-background-primary"
        style="height: 10px; width:100%;"
      ></div>
      <div class="child1">
        <b-button
          v-if="data.state == 'InProgress'"
          style="border-radius: 50px"
          type="is-primary"
          icon-pack="fas"
          icon-left="clock"
        />
        <b-button
          v-else-if="data.state == 'OK'"
          style="border-radius: 50px"
          class="is-primary"
          icon-pack="fas"
          icon-left="check"
        />
        <b-button
          v-else-if="data.state == 'KO'"
          style="border-radius: 50px"
          type="is-primary"
          icon-pack="fas"
          icon-left="times"
        />
        <b-button
          v-else
          style="border-radius: 50px"
          type="is-primary"
          icon-pack="fas"
          icon-left="circle"
        />
      </div>
      <div class="text-container">
        <div class="is-size-8" style="top: 50px" v-html="labelHtml"></div>
        <div class="label title-1">{{ $t(data.title1) }}</div>
        <div class="title-2">{{ data.title2 }}</div>
        <div class="title-2 label has-text-success" v-if="data.state == 'OK'">
          {{
            $t("formatString.completionDate", [
              formatDateStr(data.creationDate)
            ])
          }}
        </div>
        <div class="title-3 is-word-wrap-break-word">{{ data.title3 }}</div>
        <div class="px-1" style="top: 125px" v-html="errorHtml"></div>
        <b-button
          v-if="isHistoryView && histories.length"
          icon-pack="mdi"
          icon-left="eye"
          @click="isOpenDetailsDialog = true"
          size="is-small"
          class="mt-2"
          id="viewDetailBtn"
        >
          {{ $t("viewDetail") }}
        </b-button>
      </div>
    </div>

    <HistoryDetailsDialog
      v-model="isOpenDetailsDialog"
      :histories="histories"
    />
  </div>
</template>

<script>
import HistoryDetailsDialog from "@/components/super-viewer/HistoryDetailsDialog.vue";
import PicturesCollapse from "@/components/super-viewer/PicturesCollapse.vue";

export default {
  name: "HorizontalTimeLineItem",
  components: { HistoryDetailsDialog, PicturesCollapse },
  props: {
    data: {
      type: Object,
      required: true
    },
    isEventTimeline: {
      type: Boolean,
      required: false,
      default: false
    },
    isHistoryView: {
      type: Boolean,
      required: false,
      default: false
    },
    histories: {
      type: Array
    }
  },
  data() {
    return {
      /**
       * Control the HistoryDetailsDialog open or not.
       */
      isOpenDetailsDialog: false,
      /**
       * Controlling if the tooltip can be active or not. If it's false, the tooltip can't be active.
       */
      canTooltipActive: true,
      /**
       * Controlling if the image viewer is displayed or not.
       */
      isDisplayImageViewer: false,
      /**
       * Pictures array.
       */
      pictures: [],
      /**
       * The index of the selected picture.
       */
      selectedPictureIndex: 0
    };
  },
  computed: {
    zipCode: function() {
      return this.data.location?.location?.zipCode;
    },
    address: function() {
      return this.data.location?.location?.address;
    },
    city: function() {
      return this.data.location?.location?.city;
    },
    country: function() {
      return this.data.location?.location?.country;
    },
    contactName: function() {
      return this.data.location?.contact?.name;
    },
    contactEmail: function() {
      return this.data.location?.contact?.email;
    },
    contactPhone: function() {
      return this.data.location?.contact?.phone;
    },
    hasIncidents: function() {
      return !!this.data.incidents && this.data.incidents.length > 0;
    },
    incidents: function() {
      return this.data.incidents;
    },
    errorHtml: function() {
      let errorHtml = "";

      if (this.data.title4) {
        var errors = this.data.title3.split(";");
        errors.forEach(e => {
          errorHtml += `<p class='has-text-danger'>${e}</p>`;
        });
      }

      return errorHtml;
    },
    labelHtml: function() {
      if (!this.data.history) return this.data.label;

      let labelHtml = "";

      if (this.isEventTimeline) {
        const startDate = this.formatDateStr(this.data.startDate);
        const endDate = this.formatDateStr(this.data.endDate);

        if (startDate && endDate)
          labelHtml = this.$t("formatString.startDateEndDate", [
            startDate,
            endDate
          ]);
        else if (!startDate && endDate)
          labelHtml = this.$t("formatString.endDate", [endDate]);
        else if (startDate && !endDate)
          labelHtml = this.$t("formatString.startDate", [startDate]);

        labelHtml = `<p>${labelHtml}</p>`;

        return labelHtml;
      }

      for (let i = 0; i < this.data.history.length; i++) {
        let date = this.$d(
          new Date(this.data.history[i]),
          "shortWithoutTimeZone"
        );

        if (i == this.data.history.length - 1) labelHtml += `<p>${date}</p>`;
        else labelHtml += `<p>${date}</p>`;
      }

      return labelHtml;
    }
  },
  methods: {
    formatDateStr: function(dateStr) {
      return dateStr
        ? this.$d(new Date(dateStr), "shortWithoutTimeZone")
        : dateStr;
    },

    /**
     * Show the image viewer with the selected picture.
     */
    showImageViewer: function(pictures, pictureIndex) {
      // force the tooltip to close
      this.canTooltipActive = false;

      this.pictures = pictures;
      this.selectedPictureIndex = pictureIndex;

      this.isDisplayImageViewer = true;

      // prevent scrolling the whole page
      document.documentElement.classList.add("is-clipped");
    },

    /**
     * Close the image viewer.
     */
    closeImageViewer: function() {
      // allow scrolling the whole page again
      document.documentElement.classList.remove("is-clipped");
      this.isDisplayImageViewer = false;

      // allow the tooltip to be activable again
      this.canTooltipActive = true;

      // reset the pictures array and selected picture index
      this.pictures = [];
      this.selectedPictureIndex = 0;
    }
  },

  mounted() {
    if (this.$refs.tooltip) {
      this.$watch(
        () => this.$refs.tooltip.isActive,
        value => {
          if (value) {
            this.$emit("openLocationPopUp");
          }
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/_variables";

.is-inline-block {
  white-space: normal;
  position: relative;
  top: 24px;
}

.label-warning::after {
  font-family: "Font Awesome 5 Free";
  content: "\f071";
  font-weight: 900;
  position: absolute;
  color: $warning;
  padding-left: 4px;
  left: 100%;
  top: 0;
}

.horizontal-timeline-item-location-label {
  position: relative;
}

.horizontal-timeline-item-container {
  width: 400px;
  height: 40px;
  position: relative;
  white-space: normal;
}

.child1 {
  width: 100%;
  height: 40px;
  position: absolute;
  text-align: center;
}

.text-container {
  top: 105%;
  left: 5%;
  right: 5%;
  position: absolute;
  text-align: center;
}

.overlay {
  z-index: 0;
  position: absolute;
  top: 15px;
}

.location-name {
  text-align: center;
}

.title-1 {
  top: 75px;
}

.title-2 {
  top: 95px;
  font-size: 14px;
}

.title-3 {
  top: 125px;
  font-size: 14px;
  margin-top: 4px;
}

.table-th {
  font-weight: bold;
  color: #333;
}

.timeline-tooltip > .tooltip-content {
  padding: 0 !important;

  & > .content-container {
    padding: 0.35rem 0.75rem;
    height: 280px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.image-viewer {
  z-index: 100 !important;

  .b-image-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    img {
      width: 600px !important;
      height: 600px !important;
      max-height: calc(100vh - 26px) !important;
      max-width: 100vw !important;
      object-fit: scale-down !important;
    }
  }
}

.location-name-value {
  width: 100%;
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
</style>
