var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns mt-5"},[_c('div',{staticClass:"column"},[_c('b-field',{staticClass:"large-label",attrs:{"label":_vm.$t('priceInformation'),"label-position":"on-border"}},[_c('b-input',{attrs:{"type":"textarea"},on:{"blur":function($event){return _vm.$emit(
              'set-order-information',
              'priceInformation',
              _vm.priceInformation
            )}},model:{value:(_vm.priceInformation),callback:function ($$v) {_vm.priceInformation=$$v},expression:"priceInformation"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{staticClass:"large-label",attrs:{"label":_vm.$t('instructionsInformation'),"label-position":"on-border"}},[_c('b-input',{attrs:{"type":"textarea"},on:{"blur":function($event){return _vm.$emit(
              'set-order-information',
              'instructionsInformation',
              _vm.instructionsInformation
            )}},model:{value:(_vm.instructionsInformation),callback:function ($$v) {_vm.instructionsInformation=$$v},expression:"instructionsInformation"}})],1)],1)]),_c('div',{staticClass:"columns mt-5"},[_c('div',{staticClass:"column"},[_c('b-field',{staticClass:"large-label",attrs:{"label":_vm.$t('contractTermsInformation'),"label-position":"on-border"}},[_c('b-input',{attrs:{"type":"textarea"},on:{"blur":function($event){return _vm.$emit(
              'set-order-information',
              'contractTermsInformation',
              _vm.contractTermsInformation
            )}},model:{value:(_vm.contractTermsInformation),callback:function ($$v) {_vm.contractTermsInformation=$$v},expression:"contractTermsInformation"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{staticClass:"large-label",attrs:{"label":_vm.$t('specialNote'),"label-position":"on-border"}},[_c('b-input',{attrs:{"type":"textarea"},on:{"blur":function($event){return _vm.$emit('set-order-information', 'comment', _vm.comment)}},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }