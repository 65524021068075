<i18n src="@/i18n/locales/my-organization.en.json"></i18n>
<i18n src="@/i18n/locales/my-organization.fr.json"></i18n>
<i18n src="@/i18n/locales/new-customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/new-customer-order.fr.json"></i18n>

<template>
  <div
    class="is-flex is-justify-content-space-between is-fullwidth is-align-items-center"
  >
    <div class="left-container is-word-wrap-break-word">
      <div class="label" :class="status">{{ $t("invitation." + status) }}</div>

      <div v-if="role">
        {{ $t("member.role." + role) }}
      </div>
      <div>{{ email }}</div>

      <div class="is-flex" v-if="sentAt">
        <div>
          {{ $t("invitation.sentAt") + " : " + dateTimeParser(sentAt) }}
        </div>
        <div v-if="acceptedAt" class="ml-4">
          {{ $t("invitation.accepted") + " : " + dateTimeParser(acceptedAt) }}
        </div>
        <div v-if="refusedAt" class="ml-4">
          {{ $t("invitation.refused") + " : " + dateTimeParser(refusedAt) }}
        </div>
      </div>
      <div v-else>N/A</div>
    </div>
    <div v-if="status == 'pending'">
      <b-button
        icon-pack="fas"
        icon-left="trash"
        type="is-danger"
        class="ml-5"
        @click="deleteInvitation"
        :loading="isDeleting"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import invitationApi from "@/repository/customer-order/invitations.api";

export default {
  name: "InvitationInfo",
  props: {
    invitation: Object
  },
  data() {
    return {
      role: "",
      email: "",
      sentAt: null,
      acceptedAt: null,
      refusedAt: null,
      editedRole: "",
      isDeleting: false
    };
  },
  computed: {
    status() {
      if (this.sentAt && !this.acceptedAt && !this.refusedAt) return "pending";
      if (this.acceptedAt && !this.refusedAt) return "accepted";
      if (this.refusedAt && !this.acceptedAt) return "refused";
      return "unknown";
    }
  },
  mounted() {
    // Load data from api.
    this.role = this.invitation?.role;
    this.email = this.invitation?.email;
    this.sentAt = this.invitation?.sentAt;
    this.acceptedAt = this.invitation?.acceptedAt;
    this.refusedAt = this.invitation?.refusedAt;
    this.editedRole = this.invitation?.role;
  },
  methods: {
    isValidDate(date, format) {
      return moment(date, format).isValid();
    },
    dateTimeParser(date) {
      if (this.$i18n.locale == "fr") {
        if (date)
          return moment(date)
            .locale("fr")
            .format("DD/MM/YYYY HH:mm");
      }
      if (this.$i18n.locale == "en") {
        if (date)
          return moment(date)
            .locale("en")
            .format("MM/DD/YYYY HH:mm");
      }
    },
    async deleteInvitation() {
      this.$buefy.dialog.confirm({
        cancelText: this.$i18n.t("actionButtons.no"),
        confirmText: this.$i18n.t("actionButtons.sure"),
        message: this.$t("invitation.deleteContent"),
        type: "is-danger",
        iconPack: "fas",
        hasIcon: true,
        onConfirm: async () => {
          try {
            this.isDeleting = true;
            await invitationApi.deleteInvitation(this.invitation.id);
            this.$emit("update-data", null);
          } finally {
            this.isDeleting = false;
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_variables";

.label {
  line-height: 0.5rem;
}

.pending {
  color: $primary;
}

.accepted {
  color: $success;
}

.refused {
  color: $danger;
}

.left-container {
  width: calc(100% - 64px);
}
</style>
