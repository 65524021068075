<template>
  <div class="box is-radiusless is-fullheight">
    <OrderHistory :histories="histories" :key="historyKey" />
    <ContainerEvents
      :timeline-items="eventTimelines"
      :key="'container-events-' + historyKey"
    />
  </div>
</template>

<script>
import ContainerEvents from "@/components/super-viewer/ContainerEvents";
import OrderHistory from "@/components/super-viewer/OrderHistory";

export default {
  name: "ContainersBoardItemDetails",

  components: {
    ContainerEvents,
    OrderHistory
  },

  props: {
    histories: {
      type: Array,
      required: true
    },
    eventTimelines: {
      type: Array,
      required: true
    },
    historyKey: Number
  }
};
</script>
